import DragAndDrop, { DragAndDropProps } from '../../drag-and-drop/drag-and-drop';
import {
	DEFAULT_DOWNLOAD_TEMPLATE_BUTTON_TEXT,
	DEFAULT_SHOW_DOWNLOAD_TEMPLATE,
	DEFAULT_TEMPLATE_FILE_NAME,
	DefaultDragAndDropProps,
	Header
} from '../csv-uploader.consts';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import _isEmpty from 'lodash/isEmpty';
import CsvTemplateDownloader from '../csv-template-downloader/csv-template-downloader';

interface Props {
	dragAndDropProps?: Partial<DragAndDropProps>;
	onFileUploaded: (file: Blob) => void;
	headers: Header[];
	showDownloadTemplate?: boolean;
	downloadTemplateButtonText?: string;
	templateFileName?: string;
}

const DraggerView: React.FC<Props> = ({
	dragAndDropProps,
	onFileUploaded,
	headers,
	showDownloadTemplate = DEFAULT_SHOW_DOWNLOAD_TEMPLATE,
	downloadTemplateButtonText = DEFAULT_DOWNLOAD_TEMPLATE_BUTTON_TEXT,
	templateFileName = DEFAULT_TEMPLATE_FILE_NAME
}) => {
	const downloadCsvTemplate = useCallback(
		async event => {
			event.stopPropagation();
			await CsvTemplateDownloader.downloadCsv(headers, templateFileName);
		},
		[headers]
	);

	const showDownloadTemplateButton = useCallback(() => {
		if (showDownloadTemplate && !_isEmpty(headers)) {
			return {
				body: <a onClick={downloadCsvTemplate}>{downloadTemplateButtonText}</a>
			};
		}
	}, [showDownloadTemplate, headers, downloadCsvTemplate]);

	return (
		<DragAndDrop
			{...DefaultDragAndDropProps}
			{...dragAndDropProps}
			onFileUploaded={onFileUploaded}
			className={classNames('csv-drag-and-drop', dragAndDropProps?.className)}
			{...showDownloadTemplateButton()}
		/>
	);
};

export default DraggerView;
