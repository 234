import * as React from 'react';
import { Icon as AntdIcon } from 'antd';
import { IconProps as AntdIconProps } from 'antd/lib/icon';

interface IconProps extends AntdIconProps {}

const Icon: React.FC<IconProps> = (props: IconProps) => {
	return <AntdIcon {...props} />;
};

export default Icon;
