import _map from 'lodash/map';
import _every from 'lodash/every';
import _forEach from 'lodash/forEach';
import _mapValues from 'lodash/mapValues';
import _isEmpty from 'lodash/isEmpty';
import { getAncestors, getDescendants } from '../../tree-utils';
import { TreeModel } from '../../tree';
import { useEffect, useMemo } from 'react';

export interface NodeStates {
	[nodeId: string]: NodeState;
}

export enum NodeState {
	full = 'full',
	partial = 'partial',
	empty = 'empty'
}

export type NodeValues = { [nodeId: string]: boolean };

const _getNodeState = (tree: TreeModel, nodeId: string, nodesStates: NodeStates): NodeState => {
	const node = tree[nodeId];
	const childrenStates = _map(node.children, childId => nodesStates[childId]);
	if (_every(childrenStates, state => state === NodeState.empty)) return NodeState.empty;
	if (_every(childrenStates, state => state === NodeState.full)) return NodeState.full;
	return NodeState.partial;
};

const _updateParents = (tree: TreeModel, nodeId: string, nodeStates: NodeStates) => {
	const parentIds = getAncestors(tree, nodeId);
	parentIds.forEach(parentId => {
		nodeStates[parentId] = _getNodeState(tree, parentId, nodeStates);
	});
};

const _updateChildren = (
	tree: TreeModel,
	nodeId: string,
	state: NodeState,
	nodeStates: NodeStates,
	disabledNodes?: Set<string>
) => {
	const descendants = getDescendants(tree, nodeId);
	_forEach(descendants, childId => {
		if (!disabledNodes?.has(childId)) nodeStates[childId] = state;
	});
};

const _getUpdatedStates = (
	tree: TreeModel,
	nodeId: string,
	state: NodeState,
	nodeStates: NodeStates,
	disabledNodes?: Set<string>
): NodeStates => {
	const newNodeStates = { ...nodeStates };

	const isLeaf = _isEmpty(tree[nodeId].children);
	if (isLeaf) {
		newNodeStates[nodeId] = state;
	} else {
		_updateChildren(tree, nodeId, state, newNodeStates, disabledNodes);
		newNodeStates[nodeId] = _getNodeState(tree, nodeId, newNodeStates);
	}
	_updateParents(tree, nodeId, newNodeStates);

	return newNodeStates;
};

const _updateNodeStates = (
	tree: TreeModel,
	nodeId: string,
	nodeValues: NodeValues,
	nodeStates: NodeStates = {}
): NodeStates => {
	const node = tree[nodeId];

	if (node?.children) {
		for (const childId of node.children) {
			_updateNodeStates(tree, childId, nodeValues, nodeStates);
		}
		nodeStates[nodeId] = _getNodeState(tree, nodeId, nodeStates);
	} else {
		nodeStates[nodeId] = nodeValues?.[nodeId] ? NodeState.full : NodeState.empty;
	}

	return nodeStates;
};

const _getNodeValuesFromStates = (nodeStates: NodeStates): NodeValues => {
	return _mapValues(nodeStates, state => state !== NodeState.empty);
};

export const useCheckboxStates = (
	tree: TreeModel,
	nodeId: string,
	nodeValues: NodeValues,
	onChange: (nodeValues: NodeValues) => void,
	disabledNodes?: Set<string>
) => {
	const nodeStates = useMemo(() => _updateNodeStates(tree, nodeId, nodeValues), [tree, nodeId, nodeValues]);

	useEffect(() => {
		onChange(_getNodeValuesFromStates(nodeStates));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateNode = (nodeId: string, state: NodeState) => {
		const updatedStates = _getUpdatedStates(tree, nodeId, state, nodeStates, disabledNodes);
		onChange(_getNodeValuesFromStates(updatedStates));
	};

	return { updateNode, nodeStates };
};
