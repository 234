import * as React from 'react';
import { Layout as AntdLayout } from 'antd';
import { LayoutProps as AntdLayoutProps } from 'antd/lib/layout';

interface LayoutContentProps extends AntdLayoutProps {}

const LayoutContent: React.FC<LayoutContentProps> = (props: LayoutContentProps) => {
	return <AntdLayout.Content {...props} />;
};

export default LayoutContent;
