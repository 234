import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import RateWrapper from '../rate/rate';

export interface Translations {
	contact: string;
}

interface CustomerContactProps {
	name?: string;
	phone?: string;
	translations: Translations;
}

const CustomerContact: React.FC<CustomerContactProps> = ({ name, phone, translations }: CustomerContactProps) => {
	return (
		<div className="customer-contact-container">
			<div className="customer-title">{translations.contact}</div>
			<div className="customer-details">
				<div className="customer-name">{name}</div>
				<div className="rating">
					<RateWrapper disabled={true} allowHalf={true} value={3} />
				</div>
				<div className="customer-phone">
					<FontAwesomeIcon icon={faPhone} />
					<span>{phone}</span>
				</div>
			</div>
		</div>
	);
};

export default CustomerContact;
