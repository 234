import React, { useCallback } from 'react';
import { List, Avatar } from 'antd';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../button/button';
import VirtualList, { ItemRendererProps } from '../virtual-list/virtual-list';

interface Props {
	users?: Bringg.User[];
	onRemove?: (userId: number) => void;
	displayRemoveButton?: boolean;
	height: number;
	onUserClick?: (user: Bringg.User) => void;
}

const UserInfoList = ({ users, onRemove, displayRemoveButton, height, onUserClick }: Props) => {
	const handleUserClick = useCallback(
		user => () => {
			onUserClick(user);
		},
		[onUserClick]
	);

	const itemRenderer = useCallback(
		({ item }: Partial<ItemRendererProps>) => {
			const { id, name, profile_image, phone } = item;
			const onClick = handleUserClick(item);

			return (
				<List.Item
					actions={
						displayRemoveButton
							? [
									<Button onClick={() => onRemove(id)}>
										<FontAwesomeIcon icon={faTimes} />
									</Button>
							  ]
							: []
					}>
					<List.Item.Meta
						avatar={<Avatar src={profile_image} />}
						title={
							<span className="clickable-span" onClick={onClick}>
								{name}
							</span>
						}
					/>
					<span className="phone-span">{phone}</span>
				</List.Item>
			);
		},
		[handleUserClick, displayRemoveButton, onRemove]
	);

	return (
		<List itemLayout="horizontal" style={{ height }} className="users-list-container">
			<VirtualList listHeight={height} rowHeight={60} items={users} itemRenderer={itemRenderer} />
		</List>
	);
};

export default UserInfoList;
