import * as React from 'react';
import { Upload, Icon, Modal } from 'antd';
import _isFunction from 'lodash/isFunction';
import classNames from 'classnames';

interface Props {
	title?: string;
	value?: File[];
	onChange?: (files: File[]) => void;
	mandatory?: boolean;
	maxFiles?: number;
	hideModal?: boolean;
	id?: string;
}

const getBase64 = async file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
};

// this is to prevent antd from posting the upload
const dummyRequest = (object: any) => {
	setTimeout(() => {
		object.onSuccess('ok');
	}, 0);
};

class ImageUploader extends React.PureComponent<Props> {
	state = {
		previewVisible: false,
		previewImage: '',
		fileList: []
	};

	static getDerivedStateFromProps(nextProps, state) {
		if ('value' in nextProps) {
			return {
				...state,
				fileList: nextProps.value || []
			};
		}

		return null;
	}

	handlePreview = async file => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		this.setState({
			previewImage: file.url || file.preview,
			previewVisible: true
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handleChange = ({ fileList }) => {
		if (!fileList) {
			return;
		}

		if (!('value' in this.props)) {
			const { maxFiles } = this.props;

			if (maxFiles) {
				this.setState({ fileList: fileList.slice(0, maxFiles) });
			} else {
				this.setState({ fileList });
			}
		}
		this.triggerChange(fileList);
	};

	triggerChange = fileList => {
		const { onChange } = this.props;
		if (_isFunction(onChange)) {
			onChange(fileList);
		}
	};

	render() {
		const { handlePreview, handleChange } = this;
		const { previewVisible, previewImage, fileList } = this.state;
		const { title, mandatory, id, maxFiles, hideModal } = this.props;
		return (
			<div
				className={classNames('image-uploader', {
					'hide-more-files': maxFiles !== undefined && maxFiles === fileList.length,
					'hide-modal': hideModal === true
				})}>
				{title && (
					<div
						className={classNames('dynamic-form-item-title', { 'dynamic-form-item-mandatory': mandatory })}>
						{title}
					</div>
				)}

				<Upload
					id={id}
					customRequest={dummyRequest}
					listType="picture-card"
					fileList={fileList}
					onPreview={handlePreview}
					onChange={handleChange}>
					<div>
						<Icon type="plus" />
					</div>
				</Upload>
				<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
					<img alt="preview" className="image-preview" src={previewImage} />
				</Modal>
			</div>
		);
	}
}

export default ImageUploader;
