import * as React from 'react';
import { Pagination } from 'antd';

interface Props {
	showTotal?: (total: number, range: [number, number]) => React.ReactNode;
	onChange?: any;
	pageSize?: number;
	currentPage?: number;
	total?: number;
}

const PaginationWrapper: React.FunctionComponent<Props> = (props: Props) => {
	const { showTotal, onChange, pageSize, currentPage, total } = props;

	return (
		<Pagination showTotal={showTotal} onChange={onChange} pageSize={pageSize} current={currentPage} total={total} />
	);
};

export default PaginationWrapper;
