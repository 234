import React from 'react';
import Card from '../card/card-wrapper';

interface Props {
	title?: string | JSX.Element;
	body?: string | JSX.Element;
}

interface State {
	hasError: boolean;
}

class ErrorBoundaryComponent extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	static defaultProps = {
		title: 'No Data',
		body: 'could not load component'
	};

	render() {
		if (!this.state.hasError) {
			return this.props.children;
		}

		const { title, body } = this.props;

		return (
			<div className="error-component">
				<Card
					className="error-card"
					children={
						<>
							<h1 className="title">{title}</h1>
							<h2>{body}</h2>
						</>
					}
				/>
			</div>
		);
	}
}

export default ErrorBoundaryComponent;
