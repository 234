import * as React from 'react';
import { Slider } from 'antd';
import { SliderValue } from 'antd/lib/slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop } from '@fortawesome/pro-solid-svg-icons/faStop';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';

const SLIDER_STEP = 0.0045;

interface Props {
	onTimeChange: Function;
	sliderLength: number;
	sliderStep?: number;
}

interface State {
	isPlaying: boolean;
	sliderValue: SliderValue;
	interval: any;
}

class Player extends React.Component<Props, State> {
	state = {
		isPlaying: false,
		sliderValue: 0,
		interval: 0
	};

	onChange = (value: SliderValue): void => {
		this.props.onTimeChange(value);

		this.setState(() => {
			return {
				sliderValue: value
			};
		});
	};

	normalizeHours = (value: number): string => {
		if (value < 10) {
			return `0${value}`;
		}

		return value.toString();
	};

	getPlayerCurrentTime = (): string => {
		const durationInHours = this.props.sliderLength / 60 / 60;
		const durationInMinutes = this.props.sliderLength / 60;
		const startHour = parseInt((this.state.sliderValue * durationInHours).toString());
		const startMinute = parseInt(((this.state.sliderValue * durationInMinutes) % 60).toString());

		return this.getFormattedTime(startHour, startMinute);
	};

	getPlayerLeftTime = (): string => {
		const durationInHours = this.props.sliderLength / 60 / 60;
		const durationInMinutes = this.props.sliderLength / 60;
		const startHour = parseInt((this.state.sliderValue * durationInHours).toString());
		const startMinute = parseInt(((this.state.sliderValue * durationInMinutes) % 60).toString());

		let endHour = parseInt((durationInHours - startHour).toString());
		const endMinute = parseInt(((durationInMinutes - startHour * 60 - startMinute) % 60).toString());

		if (startMinute > 0) {
			endHour--;
		}
		if (endHour < 0) {
			endHour = 0;
		}

		return this.getFormattedTime(endHour, endMinute);
	};

	getFormattedTime = (hour: number, minute: number): string => {
		return `${this.normalizeHours(hour)}:${this.normalizeHours(minute)}`;
	};

	play = () => {
		this.startPlayer();

		this.setState(state => {
			return {
				...state,
				isPlaying: true
			};
		});
	};

	startPlayer = () => {
		const interval = setInterval(() => {
			this.setState(state => {
				const newValue = this.state.sliderValue + (this.props.sliderStep || SLIDER_STEP);
				this.props.onTimeChange(newValue);

				if (this.state.sliderValue < 1) {
					return {
						...state,
						sliderValue: newValue,
						interval
					};
				} else {
					this.stopPlayer();
				}
			});
		}, 1000);
	};

	stopPlayer = () => {
		clearInterval(this.state.interval);

		this.setState(() => {
			return {
				isPlaying: false,
				interval: 0
			};
		});
	};

	public render() {
		const startTime = this.getPlayerCurrentTime();
		const endTime = this.getPlayerLeftTime();

		return (
			<div className="player">
				<div className="player-actions">
					{this.state.isPlaying ? (
						<div className="stop-button" onClick={this.stopPlayer}>
							<FontAwesomeIcon icon={faStop} />
						</div>
					) : (
						<div className="play-button" onClick={this.play}>
							<FontAwesomeIcon icon={faPlay} />
						</div>
					)}
				</div>
				<div className="player-start-time">{startTime}</div>
				<div className="player-slider">
					<Slider
						min={0}
						max={1}
						value={this.state.sliderValue}
						tooltipVisible={false}
						step={this.props.sliderStep || SLIDER_STEP}
						onChange={this.onChange}
					/>
				</div>
				<div className="player-end-time">{endTime}</div>
			</div>
		);
	}
}

export default Player;
