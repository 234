import React, { useLayoutEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

const TIME_FORMAT = 'HH:mm';

export const getArriveAt = date => {
	return dayjs(date).format(TIME_FORMAT);
};

export const getHowLongFromNow = (date: Date) => {
	return dayjs(date).diff(dayjs(), 'minute');
};

interface CountDownProps {
	time: Date;
	translations: CountDownTranslations;
}

interface CountDownTranslations {
	title: string;
	minuteSubtitle: string;
}

interface ArrivalTimeProps {
	time: Date;
	translations: ArrivalTimeTranslations;
}

interface ArrivalTimeTranslations {
	title: string;
}

interface EtaProps {
	time: Date;
	translations: Translations;
}

export interface Translations {
	arrivalTimeTitle: string;
	countDownTitle: string;
	countDownMinuteSubtitle: string;
}

const CountDown: React.FC<CountDownProps> = ({ time, translations }) => {
	const [animation, setAnimation] = useState('');

	useLayoutEffect(() => {
		setAnimation('pop');
	}, [time]);

	const clearAnimation = () => {
		setAnimation('');
	};

	return (
		<div className="count-down side-a">
			<div className="title">{translations.title}</div>
			<div className={`time ${animation}`} onAnimationEnd={clearAnimation}>
				{getHowLongFromNow(time)}
			</div>
			<div className="unit">{translations.minuteSubtitle}</div>
		</div>
	);
};

const ArrivalTime: React.FC<ArrivalTimeProps> = ({ time, translations }) => {
	return (
		<div className="arrival-time side-b">
			<div className="title">{translations.title}</div>
			<div className="time">{getArriveAt(time)}</div>
		</div>
	);
};

const Eta: React.FC<EtaProps> = ({ time, translations }: EtaProps) => {
	const [flipped, setFlipped] = useState(false);
	const onClick = () => {
		setFlipped(!flipped);
	};

	return (
		<div className="eta-container">
			<div className={classNames('eta', { flipped })} onClick={onClick}>
				<CountDown
					time={time}
					translations={{
						title: translations.countDownTitle,
						minuteSubtitle: translations.countDownMinuteSubtitle
					}}
				/>
				<ArrivalTime time={time} translations={{ title: translations.arrivalTimeTitle }} />
			</div>
		</div>
	);
};

export default Eta;
