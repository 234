import * as React from 'react';
import { Table } from 'antd';
import { ColumnProps, PaginationConfig, TableEventListeners, TableSize } from 'antd/lib/table';
import classNames from 'classnames';

export type TableScroll = {
	x?: boolean | number | string;
	y?: boolean | number | string;
	scrollToFirstRowOnChange?: boolean;
};

export interface TableProps {
	collection: Array<any>;
	columns: ColumnProps<any>[];
	size?: TableSize;
	rowKey: string | ((record) => string);
	pagination?: PaginationConfig | false;
	className?: string;
	loading?: boolean;
	emptyText?: string | JSX.Element;
	rowSelection?: any;
	onChange?: Function;
	hasData?: boolean;
	useFixedHeader?: boolean;
	onRowClick?: (record: any, index: number) => TableEventListeners;
	footer?: (currentPageData: any[]) => React.ReactNode;
	scroll?: TableScroll;
	bordered?: boolean;
}

export default class TableWrapper extends React.Component<TableProps> {
	public static defaultProps = {
		pagination: false,
		size: 'default',
		collection: []
	};

	public render() {
		return (
			<Table
				className={classNames('table-wrapper', this.props.className)}
				dataSource={this.props.collection}
				pagination={this.props.pagination}
				rowSelection={this.props.rowSelection}
				rowKey={this.props.rowKey}
				useFixedHeader={this.props.useFixedHeader}
				columns={this.props.columns}
				size={this.props.size}
				loading={this.props.loading}
				locale={{
					emptyText: <div className="empty-table-text">{this.props.emptyText}</div>
				}}
				onRow={this.props.onRowClick}
				footer={this.props.footer}
				scroll={this.props.scroll}
				bordered={this.props.bordered}
			/>
		);
	}
}
