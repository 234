import * as React from 'react';
import { Rate } from 'antd';
import { RateProps } from 'antd/lib/rate';

const RateWrapper: React.FunctionComponent<RateProps> = (props: RateProps) => {
	return (
		<div className="rate-wrapper">
			<Rate {...props} />
		</div>
	);
};

RateWrapper.defaultProps = {};

export default RateWrapper;
