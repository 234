import React, { useCallback, useEffect, useState } from 'react';
import { Inventory, NoteType, SharedLocation, TaskInventory, TaskNote, WayPoint } from '@bringg/types';
import CardWrapper from '../../components/card/card-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/pro-solid-svg-icons/faBox';
import { faCommentAlt } from '@fortawesome/pro-solid-svg-icons/faCommentAlt';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons/faPaperclip';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import Tabs, { TabPane } from '../../components/tabs/tabs';
import Row from '../../components/row/row';
import Col from '../../components/col/col';
import { get as _get, partition as _partition } from 'lodash';
import _noop from 'lodash/noop';
import TaskNotes, { Translations as TaskNotesTranslations } from '../../components/task-notes/task-notes';
import WayPointTimes, {
	Translations as WayPointTimesTranslations
} from '../../components/waypoint-times/waypoint-times';
import CustomerContact, {
	Translations as CustomerContactTranslations
} from '../../components/customer-contact/customer-contact';
import TaskInventories, {
	Translations as TaskInventoriesTranslations
} from '../../components/task-inventories/task-inventories';
import TaskNotesAttachments, {
	Translations as TaskNotesAttachmentsTranslations
} from '../../components/task-notes-attachments/task-notes-attachments';
import Modal from '../../components/modal/modal';
import WayPointCardForm, {
	Translations as WayPointCardFormTranslations
} from './waypoint-card-form/waypoint-card-form';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import TaskTrackingLinks, {
	Translations as TaskTrackingLinksTranslations
} from '../../components/task-tracking-links/task-tracking-links';

export interface Translations
	extends TaskNotesTranslations,
		CustomerContactTranslations,
		WayPointTimesTranslations,
		TaskInventoriesTranslations,
		TaskNotesAttachmentsTranslations,
		WayPointCardFormTranslations,
		TaskTrackingLinksTranslations {
	notes: string;
	inventories: string;
	attachments: string;
	trackingLinks: string;
	editStop: string;
	of: string;
	stop: string;
}

interface WayPointCardProps {
	wayPoint: WayPoint;
	onSave?: (wayPoint: WayPoint) => void;
	onQuantityChange?: (massQuantityUpdate: TaskInventory[]) => void;
	onInventoriesChange?: (inventories: Inventory[]) => void;
	onNoteAdded?: (note: string) => void;
	timeZone?: string;
	taskNotes?: TaskNote[];
	taskInventories?: TaskInventory[];
	sharedLocations?: SharedLocation[];
	translations: Translations;
	editable?: boolean;
}

const DATE_FORMAT = 'MMM DD, LT';

const WayPointCard: React.FC<WayPointCardProps> = ({
	wayPoint,
	onSave = _noop,
	onQuantityChange = _noop,
	onInventoriesChange = _noop,
	onNoteAdded = _noop,
	timeZone,
	taskNotes,
	taskInventories,
	sharedLocations,
	editable = true,
	translations
}: WayPointCardProps) => {
	const [showEditForm, setShowEditForm] = useState(false);
	const [wayPointData, setWayPointData] = useState(wayPoint);

	useEffect(() => {
		setWayPointData(wayPoint);
	}, [wayPoint]);

	const showForm = useCallback(() => {
		setShowEditForm(true);
	}, [showEditForm]);

	const hideForm = useCallback(() => {
		setShowEditForm(false);
	}, [showEditForm]);

	const onFormSave = useCallback(
		(wayPoint: WayPoint) => {
			hideForm();
			setWayPointData(wayPoint);
			onSave(wayPoint);
		},
		[wayPointData]
	);

	const onEditClick = useCallback(event => {
		showForm();
		event.preventDefault();
	}, []);

	const cardTitle = (
		<div>
			<div className="waypoint-edit-container">
				<div className="waypoint-edit-stops">{`${translations.stop} ${wayPointData.position} ${translations.of} 2`}</div>
				{editable && (
					<div className="waypoint-edit-button">
						<span className="waypoint-edit-link" onClick={onEditClick}>
							{translations.editStop}
							<FontAwesomeIcon className="waypoint-edit-link-icon" icon={faPen} />
						</span>
					</div>
				)}
			</div>

			<div className="waypoint-address">{wayPointData.address}</div>
			<div className="waypoint-address-second-line">{wayPointData.address_second_line}</div>
		</div>
	);

	const tabPaneTitle = (title: string, icon): React.ReactNode => {
		return (
			<span>
				<FontAwesomeIcon icon={icon} />
				&nbsp;&nbsp;{title}
			</span>
		);
	};

	const [textualTaskNotes, graphicalTaskNotes] = _partition(taskNotes, note =>
		[NoteType.TaskNote, NoteType.HtmlTaskNote].includes(note.type)
	);

	return (
		<>
			<div className="waypoint-card">
				<CardWrapper title={cardTitle}>
					<Row>
						<Col className="waypoint-card-item" span={16}>
							<WayPointTimes
								scheduledAt={wayPointData.scheduled_at}
								eta={wayPointData.eta}
								noLaterThan={wayPointData.no_later_than}
								noEarlierThan={wayPointData.no_earlier_than}
								etl={wayPointData.etl}
								checkinTime={wayPointData.checkin_time}
								checkoutTime={wayPointData.checkout_time}
								timeZone={timeZone}
								translations={translations}
							/>
						</Col>

						<Col className="waypoint-card-item" span={8}>
							<CustomerContact
								name={wayPointData.name || _get(wayPointData, 'customer.name')}
								phone={wayPointData.phone || _get(wayPointData, 'customer.phone')}
								translations={translations}
							/>
						</Col>
					</Row>

					<Tabs>
						<TabPane
							tab={tabPaneTitle(`${translations.notes} (${textualTaskNotes.length})`, faCommentAlt)}
							key={`waypoint-${wayPointData.id}-notes`}>
							<TaskNotes
								taskNotes={textualTaskNotes}
								onNoteAdded={onNoteAdded}
								dateFormat={DATE_FORMAT}
								translations={translations}
							/>
						</TabPane>
						<TabPane
							tab={tabPaneTitle(
								`${translations.inventories} (${_get(taskInventories, 'length')})`,
								faBox
							)}
							key={`waypoint-${wayPointData.id}-inventories`}>
							<TaskInventories
								taskInventories={taskInventories}
								onQuantityChange={onQuantityChange}
								onInventoriesChange={onInventoriesChange}
								translations={translations}
							/>
						</TabPane>
						<TabPane
							tab={tabPaneTitle(
								`${translations.attachments} (${graphicalTaskNotes.length})`,
								faPaperclip
							)}
							key={`waypoint-${wayPointData.id}-attachments`}>
							<TaskNotesAttachments
								taskNotesAttachments={graphicalTaskNotes}
								dateFormat={DATE_FORMAT}
								translations={translations}
							/>
						</TabPane>

						{sharedLocations && (
							<TabPane
								tab={tabPaneTitle(
									`${translations.trackingLinks} (${sharedLocations.length})`,
									faMapMarkerAlt
								)}
								key={`waypoint-${wayPointData.id}-tracking-links`}>
								<TaskTrackingLinks
									sharedLocations={sharedLocations}
									timeZone={timeZone}
									dateFormat={DATE_FORMAT}
									translations={translations}
								/>
							</TabPane>
						)}
					</Tabs>
				</CardWrapper>
			</div>

			<Modal
				title={translations.editStop}
				className="waypoint-edit-modal"
				visible={showEditForm}
				onOk={hideForm}
				onCancel={hideForm}
				footer={null}>
				<WayPointCardForm
					wayPoint={wayPointData}
					onCancel={hideForm}
					onDelete={hideForm}
					onSave={onFormSave}
					translations={translations}
				/>
			</Modal>
		</>
	);
};

export default WayPointCard;
