import * as React from 'react';
import { Spin } from 'antd';

interface Props {
	size?: 'small' | 'default' | 'large';
	className?: string;
}

const SpinnerWrapper: React.SFC<Props> = (props: Props) => {
	const { size, className } = props;
	return (
		<div className={`${className} spinner-wrapper`}>
			<Spin size={size} />
		</div>
	);
};

SpinnerWrapper.defaultProps = {
	size: 'large'
};

export default SpinnerWrapper;
