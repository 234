import React, { useState } from 'react';
import _map from 'lodash/map';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import classNames from 'classnames';

export interface Props {
	tree: TreeModel;
	rootId: string;
	className?: string;
	renderNode?: RenderNode;
	overrideCollapse?: OverrideCollapse;
	filterNode?: FilterNode;
}

interface SubTreeProps {
	tree: TreeModel;
	node: TreeNode;
	nodeId: string;
	renderNode: RenderNode;
	overrideCollapse: OverrideCollapse;
	filterNode: FilterNode;
}

export type RenderNode = (node, nodeId) => React.ReactNode;

export type FilterNode = (nodeId: string, node) => boolean;

export type OverrideCollapse = { [nodeId: string]: boolean };

export type TreeModel = {
	[nodeId: string]: TreeNode;
};

export type TreeNode = { option: string; children: string[]; parentId: string };

const defaultRenderNode = node => {
	return <span>{node.option}</span>;
};

const Tree: React.FC<Props> = ({
	tree,
	className,
	renderNode = defaultRenderNode,
	overrideCollapse,
	filterNode = () => false,
	rootId
}) => {
	return (
		<div className={classNames('tree', className)}>
			<SubTree
				tree={tree}
				node={tree[rootId]}
				nodeId={rootId}
				renderNode={renderNode}
				overrideCollapse={overrideCollapse}
				filterNode={filterNode}
			/>
		</div>
	);
};

const SubTree: React.FC<SubTreeProps> = ({ tree, node, nodeId, renderNode, overrideCollapse, filterNode }) => {
	const [collapse, setCollapse] = useState(false);

	const onClickCollapse = () => {
		setCollapse(!collapse);
	};

	const isLeaf = _isEmpty(node?.children);

	return (
		!_isNil(node) &&
		!filterNode(nodeId, node) && (
			<div className="sub-tree">
				<div className="tree-option">
					{!isLeaf && (
						<button className="tree-collapse-button">
							<FontAwesomeIcon
								icon={faChevronDown}
								className={classNames('tree-collapse-icon', { 'collapse-tree': collapse })}
								onClick={onClickCollapse}
							/>
						</button>
					)}
					{renderNode(node, nodeId)}
				</div>
				<div className={classNames('children', { hidden: overrideCollapse?.[nodeId] ?? collapse })}>
					{_map(node.children, childId => {
						const child = tree[childId];
						return (
							<SubTree
								key={childId}
								tree={tree}
								node={child}
								nodeId={childId}
								renderNode={renderNode}
								overrideCollapse={overrideCollapse}
								filterNode={filterNode}
							/>
						);
					})}
				</div>
			</div>
		)
	);
};

export default Tree;
