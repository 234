import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/pro-solid-svg-icons/faFileCsv';
import { ModalProps } from '../modal/modal';
import { AutoCompleteProps } from '../auto-complete/auto-complete';
import { DragAndDropProps } from '../drag-and-drop/drag-and-drop';
import { TableProps } from '../table/table';
import { PaginationConfig } from 'antd/lib/pagination';
import { DataSourceItemObject } from 'antd/lib/auto-complete';

export const DEFAULT_TOTAL_ROWS_TEXT = 'Total rows:';
export const DEFAULT_COLUMNS_SELECTED_TEXT = 'Columns selected:';
export const DEFAULT_SHOW_FIRST_ROW = true;
export const DEFAULT_SHOW_DOWNLOAD_TEMPLATE = true;
export const DEFAULT_DOWNLOAD_TEMPLATE_BUTTON_TEXT = 'Download Template';
export const DEFAULT_TEMPLATE_FILE_NAME = 'template_csv_file';
export const DEFAULT_LINES_IMPORTED_TEXT = 'Total lines imported:';
export const DEFAULT_LINES_UPLOADED_TEXT = 'Total lines uploaded:';
export const DEFAULT_LINES_WITH_ERRORS_TEXT = 'Total lines with errors:';
export const DEFAULT_UPLOAD_TEXT = 'Upload';
export const DEFAULT_FINISH_TEXT = 'Close';

export interface CSVUploaderProps {
	headers?: Header[];
	headersOptions?: DataSourceItemObject[];
	onUpload: (updatedHeaders: Header[], csvFile: Blob) => Promise<CsvUploadResult>;
	description?: string;
	totalRowsText?: string;
	columnsSelectedText?: string;
	modalProps?: Partial<ModalProps>;
	autoCompleteProps?: Partial<AutoCompleteProps>;
	dragAndDropProps?: Partial<DragAndDropProps>;
	tableProps?: Partial<TableProps>;
	showFirstRow?: boolean;
	showDownloadTemplate?: boolean;
	templateFileName?: string;
	downloadTemplateButtonText?: string;
	linesUploadedText?: string;
	linesImportedText?: string;
	linesWithErrorsText?: string;
	uploadText?: string;
	finishText?: string;
}

export enum CSVUploaderState {
	DraggerView,
	TableView,
	Uploading,
	ResultsView
}

export type Header = {
	field_name: string;
	field_index: number;
};

export const DefaultPaginationProps: PaginationConfig = {
	pageSize: 5,
	hideOnSinglePage: true
};

export const DefaultAutoCompleteProps: Partial<AutoCompleteProps> = {
	allowClear: true,
	filterOption: true
};

export const DefaultModalProps: Partial<ModalProps> = {
	closable: false,
	cancelText: 'Cancel',
	title: 'CSV Uploader',
	width: 1000
};

const CsvIcon = <FontAwesomeIcon icon={faFileCsv} size={'4x'} />;

export const DefaultDragAndDropProps: Partial<DragAndDropProps> = {
	accept: '.csv',
	showUploadList: false,
	text: 'Click or drag in order to upload file',
	icon: CsvIcon
};

export type CsvUploadResult = {
	uploaded_number: number;
	failed_number: number;
	imported_number: number;
	errors: string[];
};
