import React, { FunctionComponent, PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Layout } from 'antd';
import SideMenu from './side-menu/side-menu';

const { Sider, Content } = Layout;

export interface MenuItem {
	key: string;
	title: string | ReactNode;
	icon: IconDefinition;
	path: string;
}

export interface Props {
	items: MenuItem[];
	onLogout: () => void;
	userName: string;
	userEmail: string;
	logoutText: string;
}

const SideMenuContainer: FunctionComponent<PropsWithChildren<Props>> = ({
	items,
	onLogout,
	userName,
	userEmail,
	logoutText,
	children
}) => {
	const [collapsed, setCollapsed] = useState(false);

	const onCollapseChanged = useCallback(collapsed => {
		setCollapsed(collapsed);
	}, []);

	return (
		<Layout className="side-menu-container">
			<Sider className={`menu-sider ${collapsed ? 'menu-sider-collapsed' : ''}`}>
				<SideMenu
					onCollapseChanged={onCollapseChanged}
					items={items}
					onLogout={onLogout}
					userName={userName}
					userEmail={userEmail}
					logoutText={logoutText}
					collapsed={collapsed}
				/>
			</Sider>
			<Content>{children}</Content>
		</Layout>
	);
};

export default React.memo(SideMenuContainer);
