import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryEmpty } from '@fortawesome/pro-solid-svg-icons/faBatteryEmpty';
import { faBatteryFull } from '@fortawesome/pro-solid-svg-icons/faBatteryFull';
import { faBatteryHalf } from '@fortawesome/pro-solid-svg-icons/faBatteryHalf';
import { faBatteryQuarter } from '@fortawesome/pro-solid-svg-icons/faBatteryQuarter';
import { faBatteryThreeQuarters } from '@fortawesome/pro-solid-svg-icons/faBatteryThreeQuarters';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CSSProperties } from 'react';

const LOW_BATTERY: number = 20;
const LOW_BATTERY_COLOR: string = 'red';
const BATTERY_COLOR: string = 'black';

interface Props {
	batteryPercentage: number;
	className?: string;
	style?: CSSProperties;
}

const BatteryIcon: React.FunctionComponent<Props> = (props: Props) => {
	const getIcon = (): IconProp => {
		const { batteryPercentage } = props;

		if (batteryPercentage === 0) {
			return faBatteryEmpty;
		}
		if (batteryPercentage <= 25) {
			return faBatteryQuarter;
		}
		if (batteryPercentage <= 50) {
			return faBatteryHalf;
		}
		if (batteryPercentage <= 75) {
			return faBatteryThreeQuarters;
		}

		return faBatteryFull;
	};

	const getColor = (): string => {
		const { batteryPercentage } = props;
		return batteryPercentage < LOW_BATTERY ? LOW_BATTERY_COLOR : BATTERY_COLOR;
	};

	return (
		<FontAwesomeIcon
			icon={getIcon()}
			color={getColor()}
			className={props.className}
			style={props.style}
			size={'2x'}
		/>
	);
};

export default BatteryIcon;
