import * as React from 'react';
import { Switch as AntdSwitch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

interface Props extends SwitchProps {}

const Switch: React.FunctionComponent<Props> = (props: Props) => {
	return <AntdSwitch {...props} />;
};

export default Switch;
