import * as React from 'react';
import { Tabs as AntdTabs } from 'antd';
import { TabsProps as AntdTabsProps, TabPaneProps as AntdTabPaneProps } from 'antd/lib/tabs';

interface TabsProps extends AntdTabsProps {}

interface TabPaneProps extends AntdTabPaneProps {}

const Tabs: React.FC<TabsProps> = (props: TabsProps) => {
	return (
		<div className="bringg-tabs">
			<AntdTabs tabBarGutter={8} size="large" {...props} />
		</div>
	);
};
export const TabPane: React.FC<TabPaneProps> = (props: TabPaneProps) => {
	return <AntdTabs.TabPane {...props} />;
};

export default Tabs;
