import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import notification from '@bringg/react-components/dist/components/notification/notification';
import { defaults as _defaults } from 'lodash';
import RouteChangedGuard from './route-changed-guard/route-changed-guard';
import FormSave from './form-save/form-save';
import {
	DEFAULT_FAILED_INIT_DESCRIPTION,
	DEFAULT_FAILED_INIT_TITLE,
	DEFAULT_FAILED_SUBMIT_DESCRIPTION,
	DEFAULT_FAILED_SUBMIT_TITLE,
	REFRESH_DEFAULT_TEXT,
	SAVE_GUARD_CANCEL_BUTTON,
	SAVE_GUARD_DESCRIPTION,
	SAVE_GUARD_OK_BUTTON,
	SAVE_GUARD_TITLE
} from './form-pages.consts';
import { UseFormMethods } from 'react-hook-form';
import './form-page.scss';

enum PageState {
	Init,
	FailedOnInit,
	FinishedLoadingInitialData,
	Submitting,
	FailedOnSubmit,
	Done
}

export interface Props {
	onSubmit: (data: any) => Promise<any>;
	onInitValues: () => Promise<any>;
	onReload?: () => Promise<any>;
	component: React.FunctionComponent<any>;
	failedInitPopup?: PopupDetails;
	failedSubmitPopup?: PopupDetails;
	saveGuardDetails?: ModalDetails;
	refreshText?: string;
	useSaveGuard?: boolean;
}

export interface PopupDetails {
	title?: string;
	description?: string;
}

export interface ModalDetails extends PopupDetails {
	okButtonText?: string;
	cancelButtonText?: string;
}

export interface ComponentWithForm<T> {
	form: UseFormMethods<T>;
	defaultValues?: T;
}

const FormPage = <T extends object>({
	onInitValues,
	onSubmit,
	onReload,
	component: Component,
	failedInitPopup = {},
	failedSubmitPopup = {},
	saveGuardDetails = {},
	refreshText = REFRESH_DEFAULT_TEXT,
	useSaveGuard = true
}: Props) => {
	const [defaultValues, setDefaultValues] = useState();
	const [componentState, setComponentState] = useState<PageState>(PageState.Init);

	_defaults(failedInitPopup, { title: DEFAULT_FAILED_INIT_TITLE, description: DEFAULT_FAILED_INIT_DESCRIPTION });
	_defaults(failedSubmitPopup, {
		title: DEFAULT_FAILED_SUBMIT_TITLE,
		description: DEFAULT_FAILED_SUBMIT_DESCRIPTION
	});
	_defaults(saveGuardDetails, {
		title: SAVE_GUARD_TITLE,
		description: SAVE_GUARD_DESCRIPTION,
		okButtonText: SAVE_GUARD_OK_BUTTON,
		cancelButton: SAVE_GUARD_CANCEL_BUTTON
	});

	useEffect(() => {
		const initDefaultValues = async () => {
			try {
				const values = await onInitValues();
				setDefaultValues(values);
				setComponentState(PageState.FinishedLoadingInitialData);
			} catch (e) {
				notification.error(failedInitPopup.title, failedInitPopup.description);
				console.error('failed to init values', e);
				setComponentState(PageState.FailedOnInit);
			}
		};

		initDefaultValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const form = useForm({ defaultValues });
	const {
		handleSubmit,
		reset,
		formState: { isDirty }
	} = form;

	const resetWithValues = () => reset(defaultValues);
	const handleSubmitFn = handleSubmit(async values => {
		setComponentState(PageState.Submitting);

		try {
			const newValues = await onSubmit(values as T);
			setDefaultValues(newValues);
			reset(newValues);
			setComponentState(PageState.Done);
		} catch (e) {
			notification.error(failedSubmitPopup.title, failedSubmitPopup.description);
			console.error('failed to submit values', e);
			setComponentState(PageState.FailedOnSubmit);
		}
	});

	const reloadValues = async () => {
		setComponentState(PageState.Init);

		try {
			const newValues = await onReload();
			setDefaultValues(Object.assign({}, defaultValues, newValues));
			reset(newValues);
			setComponentState(PageState.FinishedLoadingInitialData);
		} catch (e) {
			notification.error(failedInitPopup.title, failedInitPopup.description);
			console.error('failed to reload values', e);
			setComponentState(PageState.FailedOnInit);
		}
	};

	return componentState !== PageState.FailedOnInit && defaultValues ? (
		<>
			{useSaveGuard && (
				<RouteChangedGuard
					isBlocked={isDirty}
					modalTitle={saveGuardDetails.title}
					modalDescription={saveGuardDetails.description}
					modalOkText={saveGuardDetails.okButtonText}
					modalCancelText={saveGuardDetails.cancelButtonText}
				/>
			)}
			<form onSubmit={handleSubmitFn} className="form-page-form">
				<FormSave loading={componentState === PageState.Submitting} reset={resetWithValues} dirty={isDirty} />
				<Component form={form} defaultValues={defaultValues} onReload={reloadValues} />
			</form>
		</>
	) : componentState === PageState.FailedOnInit ? (
		<div>{refreshText}</div>
	) : null;
};

export default FormPage;
