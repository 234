import * as React from 'react';
import { Layout as AntdLayout } from 'antd';
import { SiderProps as AntdSiderProps } from 'antd/lib/layout/Sider';

interface LayoutSiderProps extends AntdSiderProps {}

const LayoutSider: React.FC<LayoutSiderProps> = (props: LayoutSiderProps) => {
	return <AntdLayout.Sider {...props} />;
};

export default LayoutSider;
