import React from 'react';
import { TaskNote } from '@bringg/types/types/task';
import DateDisplay from '../date-display/date-display';
import _isNil from 'lodash/isNil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import Modal from '../modal/modal';
import { useCallback, useState } from 'react';
import _noop from 'lodash/noop';
import TaskNotesForm from './task-notes-form/task-notes-form';

export interface Translations {
	addedBy: string;
	addNote: string;
	addYourNote: string;
	save: string;
	cancel: string;
}

interface TaskNotesProps {
	taskNotes: TaskNote[];
	onNoteAdded?: (note: string) => void;
	dateFormat: string;
	translations: Translations;
}

const TaskNotes: React.FC<TaskNotesProps> = ({
	taskNotes,
	onNoteAdded = _noop,
	dateFormat,
	translations
}: TaskNotesProps) => {
	const [showAddNoteForm, setShowAddNoteForm] = useState(false);

	const showForm = useCallback(() => {
		setShowAddNoteForm(true);
	}, [showAddNoteForm]);

	const hideForm = useCallback(() => {
		setShowAddNoteForm(false);
	}, [showAddNoteForm]);

	const onSaveForm = useCallback(
		(note: string) => {
			hideForm();
			onNoteAdded(note);
		},
		[onNoteAdded]
	);

	const taskNotesList = taskNotes.map((taskNote: TaskNote) => (
		<div className="task-note" key={taskNote.id}>
			{_isNil(taskNote.url) ? (
				<div className="task-note-content">{taskNote.note}</div>
			) : (
				<a className="task-note-link" href={taskNote.url.toString()} target="_blank">
					{taskNote.note}
				</a>
			)}
			<div className="task-note-info">
				{`${translations.addedBy} ${taskNote.author_name} `}
				<DateDisplay date={taskNote.created_at} format={dateFormat} />
			</div>
		</div>
	));

	return (
		<div className="task-notes-container">
			<div>{taskNotesList}</div>

			<div className="task-notes-add-note" onClick={showForm}>
				<FontAwesomeIcon icon={faPlus} className="task-notes-add-note-icon" />
				<span className="task-notes-add-note-link">{translations.addNote}</span>
			</div>

			<Modal
				title={translations.addNote}
				className="task-notes-form"
				visible={showAddNoteForm}
				onOk={hideForm}
				onCancel={hideForm}
				footer={null}>
				<TaskNotesForm onSave={onSaveForm} onCancel={hideForm} translations={translations} />
			</Modal>
		</div>
	);
};

export default TaskNotes;
