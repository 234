import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextArea from 'antd/lib/input/TextArea';
import ButtonWrapper from '../../button/button';

interface Props {
	onSave: (note: string) => void;
	onCancel: () => void;
	translations: Translations;
}

export interface Translations {
	addYourNote: string;
	save: string;
	cancel: string;
}

const TaskNotesForm: React.FC<Props> = ({ onSave, onCancel, translations }) => {
	const { control, handleSubmit, reset, formState } = useForm({ mode: 'onChange' });

	const onFormSave = useCallback(
		({ note }) => {
			onSave(note);
			reset({ note: null });
		},
		[onSave]
	);

	return (
		<form className="task-notes-form-container">
			<Controller
				name="note"
				control={control}
				rules={{ required: true }}
				as={<TextArea placeholder={translations.addYourNote} autoSize={{ minRows: 8, maxRows: 10 }} />}
			/>

			<div className="task-notes-form-buttons">
				<ButtonWrapper className="task-notes-form-button" onClick={onCancel}>
					{translations.cancel}
				</ButtonWrapper>
				<ButtonWrapper
					type="primary"
					className="task-notes-form-button"
					disabled={!formState.isValid}
					onClick={handleSubmit(onFormSave)}>
					{translations.save}
				</ButtonWrapper>
			</div>
		</form>
	);
};

export default TaskNotesForm;
