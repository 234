import * as React from 'react';
import classNames from 'classnames';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import CardHeaderWithProfileAvatar from './card-header-with-profile-avatar/card-header-with-profile-avatar';
import CardFooterWithActionButtons from './card-footer-with-action-buttons/card-footer-with-action-buttons';

export interface CardWrapperProps extends CardProps {
	notAvailableText?: string;
}

const defaultClassName = 'bringg-card';

const CardWrapper = (props: CardWrapperProps) => {
	const mergedClass = props.className ? classNames(props.className, defaultClassName) : defaultClassName;
	return (
		<Card className={mergedClass} {...props}>
			{props.children ? (
				props.children
			) : (
				<div className="no-data-title">
					{props.notAvailableText ? props.notAvailableText : 'No Data Available'}
				</div>
			)}
		</Card>
	);
};

export default CardWrapper;

export { CardHeaderWithProfileAvatar, CardFooterWithActionButtons };
