import React from 'react';
import Tree, { Props as TreeProps } from '../tree';
import { NodeValues, NodeState, useCheckboxStates } from './hooks/use-checkbox-states';
import Checkbox from './checkbox/checkbox';
import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';

export interface Props extends TreeProps {
	nodeValues: NodeValues;
	onChange: (nodeValues: NodeValues) => void;
	disabledNodes?: Set<string>;
}

const CheckboxTree: React.FC<Props> = ({
	tree,
	rootId,
	renderNode,
	nodeValues,
	onChange,
	disabledNodes,
	...otherProps
}) => {
	const { nodeStates, updateNode } = useCheckboxStates(tree, rootId, nodeValues, onChange, disabledNodes);

	return (
		<Tree
			tree={tree}
			rootId={rootId}
			{...otherProps}
			renderNode={(node, nodeId) => {
				const state = nodeStates[nodeId];
				const isDisabled = disabledNodes?.has(nodeId);
				return (
					<>
						<Checkbox
							onChange={updateNode}
							state={state}
							filled={!_isEmpty(node.children) && state !== NodeState.empty}
							id={nodeId}
							isDisabled={isDisabled}
						/>
						{_isFunction(renderNode) ? renderNode(node, nodeId) : <span>{node.option}</span>}
					</>
				);
			}}
		/>
	);
};

export default CheckboxTree;
