import React from 'react';
import classNames from 'classnames';

interface Props {
	timezone: string;
	className?: string;
}

const Timezone: React.FC<Props> = ({ timezone, className }) => {
	const formattedTimezone = timezone?.split('/')?.join(' / ');

	return <span className={classNames('timezone-display', className)}>{formattedTimezone}</span>;
};

export default Timezone;
