import * as React from 'react';
import Row from '../row/row';
import Col from '../col/col';
import DateDisplay from '../date-display/date-display';
import Timezone from '../timezone/timezone';

export interface Translations {
	scheduledFor: string;
	timeWindow: string;
	eta: string;
	etl: string;
	arrived: string;
	left: string;
}

interface WayPointTimesProps {
	scheduledAt?: string;
	eta?: string;
	noLaterThan?: string;
	noEarlierThan?: string;
	etl?: string;
	checkinTime?: string;
	checkoutTime?: string;
	timeZone?: string;
	translations: Translations;
}

const DATE_FORMAT = 'MMM DD, YYYY, LT';
const TIME_FORMAT = 'LT';

const WayPointTimes: React.FC<WayPointTimesProps> = ({
	scheduledAt,
	eta,
	noLaterThan,
	noEarlierThan,
	etl,
	checkinTime,
	checkoutTime,
	timeZone,
	translations
}: WayPointTimesProps) => {
	const getTimeColumn = (title?: string, content?: React.ReactNode) => (
		<Col span={12} className="waypoint-time-column-container">
			<span>{title}</span>
			<div className="waypoint-time-content">{content}</div>
			<Timezone timezone={timeZone} />
		</Col>
	);

	return (
		<div className="waypoint-times-container">
			<Row gutter={24}>
				{getTimeColumn(translations.scheduledFor, <DateDisplay date={scheduledAt} format={DATE_FORMAT} />)}
				{getTimeColumn(
					checkinTime ? translations.arrived : translations.eta,
					<DateDisplay date={checkinTime || eta} format={TIME_FORMAT} />
				)}
			</Row>

			<Row gutter={24}>
				{getTimeColumn(
					translations.timeWindow,
					<div>
						<DateDisplay date={noLaterThan} format={DATE_FORMAT} /> -
						<br />
						<DateDisplay date={noEarlierThan} format={DATE_FORMAT} />
					</div>
				)}
				{getTimeColumn(
					checkoutTime ? translations.left : translations.etl,
					<DateDisplay date={checkoutTime || etl} format={TIME_FORMAT} />
				)}
			</Row>
		</div>
	);
};

export default WayPointTimes;
