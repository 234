import React, { useCallback, useEffect, useState } from 'react';
import { Inventory, TaskInventory as TaskInventoryType } from '@bringg/types';
import TaskInventory, { Translations } from '../task-inventory';
import _forEach from 'lodash/forEach';
import _toNumber from 'lodash/toNumber';
import _isNil from 'lodash/isNil';
import ButtonWrapper from '../../button/button';
import { useForm } from 'react-hook-form';

interface Props {
	parentTaskInventory: TaskInventoryType;
	taskInventories: TaskInventoryType[];
	onQuantityChange: (massQuantityUpdate: TaskInventoryType[]) => void;
	onInventoriesChange: (inventories: Inventory[]) => void;
	onSave: () => void;
	onCancel: () => void;
	translations: Translations;
}

export const TASK_INVENTORY_CONSTANTS = {
	nameDelimiter: '-',
	width: 'width',
	height: 'height',
	length: 'length',
	originalQuantity: 'original_quantity'
};

const TaskInventoryForm: React.FC<Props> = props => {
	return (
		<form className="task-inventory-card-form">
			<TaskInventoryWithChildren {...props} />
		</form>
	);
};

const TaskInventoryWithChildren: React.FC<Props> = ({
	parentTaskInventory,
	taskInventories,
	onQuantityChange,
	onInventoriesChange,
	onSave,
	onCancel,
	translations
}) => {
	const [rootTaskInventory, setRootTaskInventory] = useState<TaskInventoryType>();
	const [taskInventoriesMap, setTaskInventoriesMap] = useState<{
		[key: number]: { taskInventory: TaskInventoryType; children: number[] };
	}>({});
	const form = useForm();
	const { handleSubmit, formState } = form;
	const { dirtyFields } = formState;

	useEffect(() => {
		setRootTaskInventory(parentTaskInventory);
	}, [parentTaskInventory]);

	useEffect(() => {
		const inventoriesMap = {};

		const addTaskInventoryToMap = (
			id: number,
			taskInventory?: TaskInventoryType,
			parentTaskInventoryId?: number
		) => {
			inventoriesMap[id] = {
				taskInventory: taskInventory,
				children: [],
				parentId: parentTaskInventoryId
			};
		};

		_forEach(taskInventories, taskInventory => {
			const { id, parent_task_inventory_id } = taskInventory;

			if (!inventoriesMap[id]) {
				addTaskInventoryToMap(id, taskInventory, parent_task_inventory_id);
			}

			if (!_isNil(parent_task_inventory_id)) {
				if (!inventoriesMap[parent_task_inventory_id]) {
					addTaskInventoryToMap(parent_task_inventory_id);
				}

				inventoriesMap[parent_task_inventory_id].children.push(id);
			}
		});

		setTaskInventoriesMap(inventoriesMap);
	}, [taskInventories]);

	const convertTaskInventoriesMapToList = (
		parentTaskInventoryId: number = rootTaskInventory && rootTaskInventory.id,
		taskInventoriesTree: JSX.Element[] = [],
		depth: number = 0
	) => {
		if (!parentTaskInventoryId) {
			return;
		}

		const parentTaskInventory = taskInventoriesMap[parentTaskInventoryId];

		if (!parentTaskInventory) {
			return;
		}

		taskInventoriesTree.push(
			<div
				style={{ width: `${100 - depth * 5}%` }}
				className="task-inventory-container"
				key={parentTaskInventoryId}>
				<TaskInventory
					parentTaskInventory={parentTaskInventory.taskInventory}
					translations={translations}
					isInEditMode={true}
					form={form}
				/>
			</div>
		);

		_forEach(parentTaskInventory.children, childTaskInventoryId =>
			convertTaskInventoriesMapToList(childTaskInventoryId, taskInventoriesTree, depth + 1)
		);

		return taskInventoriesTree;
	};

	const handleTaskInventoryChange = useCallback(
		formValues => {
			const massQuantityChange: TaskInventoryType[] = [];
			const massInventoriesChange: { [key: number]: Inventory } = {};

			Object.keys(dirtyFields).forEach(dirtyField => {
				const [taskInventoryId, fieldName] = dirtyField.split(TASK_INVENTORY_CONSTANTS.nameDelimiter);

				if (fieldName === TASK_INVENTORY_CONSTANTS.originalQuantity) {
					massQuantityChange.push({
						...taskInventoriesMap[taskInventoryId].taskInventory,
						original_quantity: formValues[dirtyField]
					});
				} else {
					if (!massInventoriesChange[taskInventoryId]) {
						massInventoriesChange[taskInventoryId] =
							taskInventoriesMap[taskInventoryId].taskInventory.inventory;
					}

					massInventoriesChange[taskInventoryId] = {
						...massInventoriesChange[taskInventoryId],
						[fieldName]: _toNumber(formValues[dirtyField])
					};
				}
			});

			if (massQuantityChange.length) {
				onQuantityChange(massQuantityChange);
			}

			const inventoriesChangeList = Object.values(massInventoriesChange);

			if (inventoriesChangeList.length) {
				onInventoriesChange(inventoriesChangeList);
			}
		},
		[taskInventoriesMap, onQuantityChange, onInventoriesChange]
	);

	const onSaveClick = useCallback(
		formValues => {
			handleTaskInventoryChange(formValues);
			onSave();
		},
		[taskInventoriesMap, onSave, onQuantityChange, onInventoriesChange]
	);

	const taskInventoriesList = convertTaskInventoriesMapToList();

	return (
		<>
			<div className="task-inventories-list">{taskInventoriesList}</div>

			<div className="task-inventory-card-form-buttons">
				<ButtonWrapper className="task-inventory-card-form-button" onClick={onCancel}>
					{translations.cancel}
				</ButtonWrapper>
				<ButtonWrapper
					type={'primary'}
					className="task-inventory-card-form-button"
					onClick={handleSubmit(onSaveClick)}>
					{translations.save}
				</ButtonWrapper>
			</div>
		</>
	);
};

export default TaskInventoryForm;
