import * as React from 'react';
import { Col, Form } from 'antd';
import { FormItemProps, FormComponentProps } from 'antd/lib/form';
import _noop from 'lodash/noop';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';

const FormItem = Form.Item;

const REACT_COLUMNS_NUM: number = 24;

interface Props extends FormComponentProps {
	className?: string;
	onSubmit?: (error: any, values: any) => void;
	children: JSX.Element[];
	numberOfColumns?: number;
	showSubmitButton?: boolean;
	cancelButton?: JSX.Element;
	submitButton?: JSX.Element;
	onResetForm?: () => void;
	initialValue?: any;
	layout?: any;
}

interface FormChildrenProps extends FormItemProps {
	options?: GetFieldDecoratorOptions;
	className?: string;
	id: string;
	formItemsProps?: FormItemProps;
}

export const FormChildren: React.FunctionComponent<FormChildrenProps> = (props: FormChildrenProps) => {
	return null;
};

const formItemLayout = formLayout =>
	formLayout === 'horizontal'
		? {
				labelCol: { span: 12 },
				wrapperCol: { span: 10 }
		  }
		: null;

class FormWrapper extends React.Component<Props> {
	static defaultProps = {
		numberOfColumns: 2,
		onResetForm: _noop,
		showSubmitButton: true,
		showCancelButton: true,
		layout: 'vertical'
	};

	handleSubmit = async (event: any): Promise<void> => {
		event.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			this.props.onSubmit(err, values);
		});
	};

	resetForm = (): void => {
		this.props.form.resetFields();
		this.props.onResetForm();
	};

	render() {
		return (
			<Form onSubmit={this.handleSubmit} className={this.props.className} layout={this.props.layout}>
				{this.props.children.map(item =>
					item.type === FormChildren ? (
						<Col span={REACT_COLUMNS_NUM / this.props.numberOfColumns} key={item.props.id}>
							<FormItem {...item.props.formItemsProps} {...formItemLayout(this.props.layout)}>
								{item.props.options
									? this.props.form.getFieldDecorator(
											String(item.props.id),
											item.props.options
									  )(item.props.children)
									: item.props.children}
							</FormItem>
						</Col>
					) : (
						item
					)
				)}
				{this.props.submitButton}
				{this.props.cancelButton}
			</Form>
		);
	}
}

const FormWithValidationWrapper = Form.create<Props>()(FormWrapper);
export default FormWithValidationWrapper;
