import * as React from 'react';
import { Menu as AntdMenu } from 'antd';
import { MenuItemProps as AntdMenuItemProps } from 'antd/lib/menu/MenuItem';

interface MenuItemProps extends AntdMenuItemProps {}

const MenuItem: React.FC<MenuItemProps> = (props: MenuItemProps) => {
	return <AntdMenu.Item {...props} />;
};

export default MenuItem;
