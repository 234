import * as React from 'react';
import { Form } from 'antd';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { FormItemProps } from 'antd/lib/form';

type FieldDecoratorProps = {
	id: string;
	getFieldDecorator: (id: string, options?: GetFieldDecoratorOptions) => any;
	options?: GetFieldDecoratorOptions;
	Component: React.ReactNode;
};

export type Props = FormItemProps & FieldDecoratorProps;

const FormItemWrapper = (props: Props) => {
	const { id, getFieldDecorator, options, Component, ...formItemProps } = props;
	return <Form.Item {...formItemProps}>{getFieldDecorator(id, options)(Component)}</Form.Item>;
};

export default FormItemWrapper;
