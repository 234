import * as React from 'react';
import { Modal as AntdModal } from 'antd';
import { ModalProps as AntdModalProps } from 'antd/lib/modal';

const { confirm } = AntdModal;

export interface ModalProps extends AntdModalProps {}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
	return <AntdModal {...props} />;
};

export default Modal;
export { confirm };
