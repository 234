import * as React from 'react';
import classNames from 'classnames';
import { Tag as AntdTag } from 'antd';
import { TagProps as AntdTagProps } from 'antd/lib/tag';

interface TagProps extends AntdTagProps {}

const Tag: React.FC<TagProps> = ({ className, ...tagProps }: TagProps) => {
	const combinedClassName = classNames('bringg-tag', className);
	return <AntdTag className={combinedClassName} {...tagProps} />;
};

export default Tag;
