import React, { Component } from 'react';
import { Form, Input, Card } from 'antd';
import { FormComponentProps, WrappedFormUtils } from 'antd/es/form/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import _first from 'lodash/first';
import _find from 'lodash/find';
import { MerchantData, MoreDataAuthResponse } from '@bringg/types';
import Button from '../button/button';
import Select, { Option } from '../select/select';

export interface LoginResponse {
	success: boolean;
	data: any;
}

interface Props {
	form: WrappedFormUtils & FormComponentProps;
	onLogin: (
		email: string,
		password: string,
		selectedMerchant?: MerchantData,
		captcha?: string
	) => Promise<LoginResponse>;
	footer?: React.ReactNode;
	onLoggedIn: () => void;
	recaptchaSiteKey: string;
	onLoginFailed: (e?: Error) => void;
	passwordPlaceholder: string;
	emailPlaceholder: string;
	invalidEmailText: string;
	invalidPasswordText: string;
	loginTitle: string;
	signInText: string;
	minPasswordLength: number;
}

interface State {
	loggingIn: boolean;
	recaptchaSToken: string | null;
	captchaValue?: string;
	merchantOptions?: MerchantData[];
	selectedMerchant?: MerchantData;
}

export class Login extends Component<Props, State> {
	state = {
		loggingIn: false,
		recaptchaSToken: null,
		captchaValue: undefined,
		merchantOptions: undefined,
		selectedMerchant: undefined
	};

	onLogin = async (email: string, password: string) => {
		try {
			const response = await this.props.onLogin(
				email,
				password,
				this.state.selectedMerchant,
				this.state.captchaValue
			);

			if (response.success) {
				this.props.onLoggedIn();
			} else {
				const data = response.data as Partial<MoreDataAuthResponse>;

				if (data.recaptcha_stoken) {
					this.setState({
						recaptchaSToken: data.recaptcha_stoken,
						loggingIn: false
					});
				} else if (data.merchants_data) {
					this.setState({
						merchantOptions: data.merchants_data,
						recaptchaSToken: undefined,
						selectedMerchant: _first(data.merchants_data),
						loggingIn: false
					});
				}
			}
		} catch (e) {
			this.props.onLoginFailed(e);
			this.setState({
				recaptchaSToken: null,
				selectedMerchant: null,
				loggingIn: false
			});
		}
	};

	onClick = async e => {
		e.preventDefault();
		this.setState({ loggingIn: true });
		await this.props.form.validateFields(async err => {
			if (!err) {
				const { email, password } = this.props.form.getFieldsValue();
				await this.onLogin(email, password);
			} else {
				this.setState({ loggingIn: false });
			}
		});
	};

	storeCaptchaResponse = captchaValue => {
		this.setState({ captchaValue });
	};

	onMerchantSelect = selectedMerchantUUID => {
		this.setState({ selectedMerchant: _find(this.state.merchantOptions, { uuid: selectedMerchantUUID }) });
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { loggingIn } = this.state;
		const {
			footer,
			recaptchaSiteKey,
			loginTitle,
			invalidEmailText,
			emailPlaceholder,
			invalidPasswordText,
			passwordPlaceholder,
			signInText,
			minPasswordLength
		} = this.props;

		return (
			<div className="login-page-wrapper">
				<div className="login-card">
					<Card>
						<div className="login-header">
							<div className="login-title">
								<div>{loginTitle}</div>
								<div className="black-block" />
							</div>
						</div>
						<div className="login-body">
							<Form onSubmit={this.onClick}>
								<Form.Item>
									{getFieldDecorator('email', {
										rules: [
											{
												type: 'email',
												message: invalidEmailText,
												required: true
											}
										],
										validateTrigger: 'onSubmit'
									})(<Input data-testid="email" className="input" placeholder={emailPlaceholder} />)}
								</Form.Item>

								<Form.Item>
									{getFieldDecorator('password', {
										rules: [
											{
												min: minPasswordLength,
												message: invalidPasswordText,
												required: true
											}
										],
										validateTrigger: 'onSubmit'
									})(
										<Input
											data-testid="password"
											className="input"
											type="password"
											placeholder={passwordPlaceholder}
										/>
									)}
								</Form.Item>

								{this.state.recaptchaSToken ? (
									<ReCAPTCHA
										data-testid="captcha"
										onChange={this.storeCaptchaResponse}
										sitekey={recaptchaSiteKey}
									/>
								) : null}

								{this.state.merchantOptions ? (
									<Select
										data-testid="merchant-options"
										value={this.state.selectedMerchant.uuid}
										allowClear={false}
										onChange={this.onMerchantSelect}
										className="input multi-merchant">
										{this.state.merchantOptions.map(merchant => (
											<Option value={merchant.uuid} key={merchant.uuid}>
												{merchant.name}
											</Option>
										))}
									</Select>
								) : null}
								<Button
									className="login-button"
									data-testid="login-button"
									htmlType="submit"
									type="primary"
									disabled={loggingIn}
									loading={loggingIn}>
									{signInText}
								</Button>
							</Form>
						</div>
					</Card>
				</div>
				{footer && <div className="login-footer">{footer}</div>}
			</div>
		);
	}
}

const WrappedLogin = Form.create<Props>({ name: 'login' })(Login);
export default WrappedLogin;
