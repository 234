import React, { FunctionComponent, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router';
import Modal from '@bringg/react-components/dist/components/modal/modal';
import { Location } from 'history';

interface Props {
	isBlocked: boolean;
	modalTitle?: string;
	modalDescription?: string;
	modalOkText?: string;
	modalCancelText?: string;
}

const RouteChangedGuard: FunctionComponent<Props> = ({
	isBlocked,
	modalTitle,
	modalDescription,
	modalOkText,
	modalCancelText
}: Props) => {
	const [modalVisible, setModalVisible] = useState(false);
	const [lastLocation, setLastLocation] = useState(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (confirmedNavigation) {
			history.push(lastLocation.pathname);
		}
	}, [history, confirmedNavigation, lastLocation]);

	const showModal = location => {
		setModalVisible(true);
		setLastLocation(location);
	};

	const closeModal = () => {
		setModalVisible(false);
	};

	const handleBlockedNavigation = (nextLocation: Location): boolean => {
		if (!confirmedNavigation && isBlocked) {
			showModal(nextLocation);
			return false;
		}

		return true;
	};

	const handleConfirmNavigationClick = () => {
		if (lastLocation) {
			setConfirmedNavigation(true);
			closeModal();
		}
	};

	return (
		<div>
			<Modal
				visible={modalVisible}
				title={modalTitle}
				onCancel={closeModal}
				onOk={handleConfirmNavigationClick}
				okText={modalOkText}
				cancelText={modalCancelText}
			>
				<span>{modalDescription}</span>
			</Modal>
			<Prompt when={isBlocked} message={handleBlockedNavigation} />
		</div>
	);
};

export default RouteChangedGuard;
