import * as React from 'react';
import _isNil from 'lodash/isNil';

interface Props {
	metricValue: number;
	metricType: MetricType;
	className?: string;
}

export enum MetricType {
	NUMBER,
	PERCENTAGE, // from 5 to 5%
	CONVERT_TO_PERCENTAGE, // from 0.05 to 5%
	TIME,
	MONEY,
	DURATION
}

export type TimeOption = Intl.DateTimeFormatOptions;

const MILLION: number = 1000000;
const THOUSAND: number = 1000;
const MINUTE_IN_SECONDS: number = 60;
const FRACTION_DIGITS: number = 2;

const TimeOptions = { hour12: false, hour: 'numeric', minute: 'numeric' };

const DEFAULT_MONEY_SIGN: string = '$';
const MILLION_SIGN: string = 'M';
const THOUSAND_SIGN: string = 'K';
const MINUTES_SIGN: string = 'm';
const SECONDS_SIGN: string = 's';

const getDataFormatByType = (metricValue: number, metricType: number) => {
	if (_isNil(metricValue)) {
		return null;
	}

	switch (metricType) {
		case MetricType.NUMBER:
			return convertNumberValue(metricValue);
		case MetricType.MONEY:
			return `${DEFAULT_MONEY_SIGN}${convertNumberValue(metricValue)}`;
		case MetricType.PERCENTAGE:
			return `${metricValue}%`;
		case MetricType.CONVERT_TO_PERCENTAGE:
			return `${(metricValue * 100).toFixed()}%`;
		case MetricType.TIME:
			return new Date(metricValue).toLocaleString('default', TimeOptions);
		case MetricType.DURATION:
			return convertDuration(metricValue);
		default:
			return metricValue;
	}
};

const convertNumberValue = (metricValue: number) => {
	if (metricValue > MILLION) {
		return `${+(metricValue / MILLION).toFixed(FRACTION_DIGITS)}${MILLION_SIGN}`;
	} else if (metricValue > THOUSAND) {
		return `${+(metricValue / THOUSAND).toFixed(FRACTION_DIGITS)}${THOUSAND_SIGN}`;
	}

	return +metricValue.toFixed(FRACTION_DIGITS);
};

const convertDuration = (metricValue: number): string => {
	const minutes: number = Math.floor(metricValue / MINUTE_IN_SECONDS);
	const seconds: number = Math.floor(metricValue % MINUTE_IN_SECONDS);

	return `${minutes > 0 ? minutes + MINUTES_SIGN : ''}${seconds > 0 ? seconds + SECONDS_SIGN : ''}`;
};

const MetricCounter = ({ metricValue, metricType, className }: Props) => {
	return <div className={className}>{getDataFormatByType(metricValue, metricType)}</div>;
};

export default MetricCounter;
