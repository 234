import * as React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';

interface Props extends Omit<ButtonProps, 'children' | 'loading'> {
	children?: React.ReactNode[] | React.ReactNode;
	loading?: boolean;
}

const ButtonWrapper: React.FunctionComponent<Props> = props => {
	const { children, ...otherProps } = props;

	return <Button {...otherProps}>{children}</Button>;
};

ButtonWrapper.defaultProps = {
	size: 'middle',
	type: 'default'
};

export default ButtonWrapper;
