import React from 'react';
import TaskInventory, { Translations as TaskInventoryTranslations } from '../task-inventory/task-inventory';
import { Inventory, TaskInventory as TaskInventoryType } from '@bringg/types';
import _isNil from 'lodash/isNil';

export interface Translations extends TaskInventoryTranslations {}

interface TaskInventoriesProps {
	taskInventories: TaskInventoryType[];
	onQuantityChange?: (massQuantityUpdate: TaskInventoryType[]) => void;
	onInventoriesChange?: (inventories: Inventory[]) => void;
	translations: Translations;
}

const TaskInventories: React.FC<TaskInventoriesProps> = ({
	taskInventories,
	onQuantityChange,
	onInventoriesChange,
	translations
}: TaskInventoriesProps) => {
	const taskInventoriesList = taskInventories.map((taskInventory: TaskInventoryType) => {
		if (_isNil(taskInventory.parent_task_inventory_id)) {
			return (
				<TaskInventory
					key={taskInventory.id}
					parentTaskInventory={taskInventory}
					taskInventories={taskInventories}
					onQuantityChange={onQuantityChange}
					onInventoriesChange={onInventoriesChange}
					translations={translations}
				/>
			);
		}
	});

	return <div className="task-inventories-container">{taskInventoriesList}</div>;
};

export default TaskInventories;
