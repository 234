import React, { ReactElement, useCallback, useState } from 'react';
import Input from '../../../components/floating-placeholder-input/input';
import { DatePicker, Popconfirm } from 'antd';
import moment, { Moment } from 'moment';
import TimePickerWrapper from '../../../components/time-picker/time-picker';
import { WayPoint } from '@bringg/types';
import classNames from 'classnames';
import ButtonWrapper from '../../../components/button/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { Controller, useForm } from 'react-hook-form';
import { mapValues as _mapValues, get as _get } from 'lodash';
import { Switch } from '../../../components';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';

export interface Translations {
	destination: string;
	addressLineOne: string;
	addressLineTwo: string;
	scheduleTo: string;
	date: string;
	time: string;
	startDate: string;
	startTime: string;
	timeWindow: string;
	endDate: string;
	endTime: string;
	contact: string;
	name: string;
	phone: string;
	confirmDelete: string;
	yes: string;
	no: string;
	asap: string;
}

interface WayPointCardFormProps {
	wayPoint: WayPoint;
	onSave: (wayPoint: WayPoint) => void;
	onCancel: () => void;
	onDelete: (wayPointId: number) => void;
	translations: Translations;
}

const getFormSection = (
	title: string,
	content: ReactElement,
	inlineSection = false,
	className?: string
): ReactElement => (
	<div
		className={classNames(
			'waypoint-card-form-section',
			{ 'waypoint-card-form-inline-section': inlineSection },
			className
		)}
		section-title={title}>
		{content}
	</div>
);

const convertDateToMoment = (dateString: string): Moment => {
	if (!dateString) {
		return null;
	}

	return moment(dateString);
};

const WayPointCardForm: React.FC<WayPointCardFormProps> = ({ wayPoint, onSave, onCancel, onDelete, translations }) => {
	const [wayPointData, setWayPointData] = useState({ ...wayPoint });
	const { control, handleSubmit } = useForm();

	const onSubmit = useCallback((formFields, event) => {
		const editedWayPointData = _mapValues(formFields, field =>
			moment.isMoment(field) ? (field as Moment).toISOString() : field
		);

		setWayPointData(Object.assign(wayPointData, editedWayPointData));
		onSave(wayPointData);
	}, []);

	return (
		<form className="waypoint-card-form">
			{getFormSection(
				translations.destination,
				<>
					<Controller
						as={<Input className="waypoint-card-form-input" placeholder={translations.addressLineOne} />}
						defaultValue={wayPointData.address}
						name={'address'}
						control={control}
					/>

					<Controller
						as={<Input className="waypoint-card-form-input" placeholder={translations.addressLineTwo} />}
						defaultValue={wayPointData.address_second_line}
						name={'address_second_line'}
						control={control}
					/>
				</>
			)}

			{getFormSection(
				translations.scheduleTo,
				<>
					<div className="waypoint-card-form-date-picker-container" input-placeholder={translations.date}>
						<Controller
							as={<DatePicker className="waypoint-card-form-picker" allowClear={false} />}
							defaultValue={convertDateToMoment(wayPointData.scheduled_at)}
							name={'scheduled_at'}
							control={control}
						/>
					</div>

					<div className="waypoint-card-form-date-picker-container" input-placeholder={translations.time}>
						<Controller
							render={({ value, onChange }) => (
								<TimePickerWrapper
									value={value}
									handleTimeChange={onChange}
									className="waypoint-card-form-picker"
									allowClear={false}
								/>
							)}
							defaultValue={convertDateToMoment(wayPointData.scheduled_at)}
							name={'scheduled_at'}
							control={control}
						/>
					</div>

					<div className="waypoint-card-form-asap-container">
						<Controller
							render={({ value, onChange }) => (
								<Switch
									checked={value}
									onChange={onChange}
									unCheckedChildren={<FontAwesomeIcon icon={faTimes} />}
									checkedChildren={<FontAwesomeIcon icon={faCheck} />}
								/>
							)}
							name={'asap'}
							defaultValue={wayPointData.asap}
							control={control}
						/>
						<span>{translations.asap}</span>
					</div>
				</>,
				true,
				'waypoint-card-form-inline-section-three'
			)}

			{getFormSection(
				translations.timeWindow,
				<>
					<div
						className="waypoint-card-form-date-picker-container"
						input-placeholder={translations.startDate}>
						<Controller
							as={<DatePicker className="waypoint-card-form-picker" allowClear={false} />}
							defaultValue={convertDateToMoment(wayPointData.no_later_than)}
							name={'no_later_than'}
							control={control}
						/>
					</div>

					<div
						className="waypoint-card-form-date-picker-container"
						input-placeholder={translations.startTime}>
						<Controller
							render={({ value, onChange }) => (
								<TimePickerWrapper
									value={value}
									handleTimeChange={onChange}
									className="waypoint-card-form-picker"
									allowClear={false}
								/>
							)}
							defaultValue={convertDateToMoment(wayPointData.no_later_than)}
							name={'no_later_than'}
							control={control}
						/>
					</div>

					<div className="waypoint-card-form-date-picker-container" input-placeholder={translations.endDate}>
						<Controller
							as={<DatePicker className="waypoint-card-form-picker" allowClear={false} />}
							defaultValue={convertDateToMoment(wayPointData.no_earlier_than)}
							name={'no_earlier_than'}
							control={control}
						/>
					</div>

					<div className="waypoint-card-form-date-picker-container" input-placeholder={translations.endTime}>
						<Controller
							render={({ value, onChange }) => (
								<TimePickerWrapper
									value={value}
									handleTimeChange={onChange}
									className="waypoint-card-form-picker"
									allowClear={false}
								/>
							)}
							defaultValue={convertDateToMoment(wayPointData.no_earlier_than)}
							name={'no_earlier_than'}
							control={control}
						/>
					</div>
				</>,
				true
			)}

			{getFormSection(
				translations.contact,
				<>
					<Controller
						as={<Input className="waypoint-card-form-input" placeholder={translations.name} />}
						defaultValue={wayPointData.name || _get(wayPointData, 'customer.name')}
						name={'name'}
						control={control}
					/>
					<Controller
						as={<Input className="waypoint-card-form-input" placeholder={translations.phone} />}
						defaultValue={wayPointData.phone || _get(wayPointData, 'customer.phone')}
						name={'phone'}
						control={control}
					/>
				</>,
				true
			)}

			<div className="waypoint-card-form-buttons">
				<div>
					<Popconfirm
						overlayClassName="waypoint-card-form-confirm-delete"
						placement="topLeft"
						title={translations.confirmDelete}
						icon={<FontAwesomeIcon className="anticon" icon={faQuestionCircle} />}
						onConfirm={() => onDelete(wayPointData.id)}
						okText={translations.yes}
						cancelText={translations.no}>
						<ButtonWrapper type={'danger'} className="waypoint-card-form-button">
							Delete
						</ButtonWrapper>
					</Popconfirm>
				</div>

				<div>
					<ButtonWrapper className="waypoint-card-form-button" onClick={onCancel}>
						Cancel
					</ButtonWrapper>
					<ButtonWrapper
						type={'primary'}
						className="waypoint-card-form-button"
						onClick={handleSubmit(onSubmit)}>
						Save
					</ButtonWrapper>
				</div>
			</div>
		</form>
	);
};

export default WayPointCardForm;
