import React from 'react';
import AvatarWrapper from '../../avatar/avatar';

interface Props {
	avatarUrl?: string;
	id?: string | number;
	name?: string;
}

const CardHeaderWithProfileAvatar: React.FunctionComponent<Props> = ({ avatarUrl, name, id }: Props) => {
	return (
		<div className="card-header-with-profile-avatar">
			<div className="card-header-avatar">
				<AvatarWrapper size="large" src={avatarUrl} alt={name} />
			</div>
			<div className="card-header-body">
				<h5 className="card-header-id">{id}</h5>
				<h3 className="card-header-text">{name}</h3>
			</div>
		</div>
	);
};

export default CardHeaderWithProfileAvatar;
