import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import Button from '@bringg/react-components/dist/components/button/button';

interface Props {
	reset: () => void;
	dirty: boolean;
	loading?: boolean;
	revert_text?: string;
	save_text?: string;
	unsaved_changes_text?: string;
}

const DEFAULT_REVERT_TEXT = 'revert back';
const DEFAULT_SAVE_TEXT = 'Save changes';
const DEFAULT_UNSAVED_CHANGES_TEXT = 'Unsaved changes have been made,';

const FormSave: FunctionComponent<Props> = ({
	reset,
	dirty,
	loading,
	revert_text = DEFAULT_REVERT_TEXT,
	save_text = DEFAULT_SAVE_TEXT,
	unsaved_changes_text = DEFAULT_UNSAVED_CHANGES_TEXT
}) => {
	const revert = () => reset();

	return (
		dirty && (
			<div className="form-save">
				<div className="save-body">
					<div className="save-info">
						<FontAwesomeIcon icon={faInfoCircle} />
						<span className="save-body-text">{unsaved_changes_text} </span>
						<span className="save-body-revert" onClick={revert}>
							{revert_text}
						</span>
					</div>
					<Button loading={loading} className="save-button" htmlType="submit">
						{save_text}
					</Button>
				</div>
			</div>
		)
	);
};

export default FormSave;
