import React from 'react';
import { isFunction as _isFunction } from 'lodash';

function useCombinedRefs(...refs) {
	const targetRef = React.useRef();

	React.useEffect(() => {
		refs.forEach(ref => {
			if (!ref) return;

			if (_isFunction(ref)) {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
}

export default useCombinedRefs;
