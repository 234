import _forEach from 'lodash/forEach';

export const getAncestors = (tree, nodeId) => {
	const ancestors = [];
	_populateAncestors(tree, nodeId, ancestors);
	return ancestors;
};

export const getDescendants = (tree, nodeId) => {
	const descendants = [];
	_populateDescendants(tree, nodeId, descendants);
	return descendants;
};

const _populateAncestors = (tree, nodeId, parentIds) => {
	const parentId = tree?.[nodeId]?.parentId;
	if (parentId) {
		parentIds.push(parentId);
		_populateAncestors(tree, parentId, parentIds);
	}
};

const _populateDescendants = (tree, nodeId, childrenIds) => {
	const children = tree?.[nodeId]?.children;
	_forEach(children, childId => {
		childrenIds.push(childId);
		_populateDescendants(tree, childId, childrenIds);
	});
};
