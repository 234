import { DatePicker } from 'antd';
import * as React from 'react';
import { RangePickerProps } from 'antd/lib/date-picker/interface';

const { RangePicker } = DatePicker;

const DEFAULT_FORMAT = 'YYYY-MM-DD';

interface Props extends RangePickerProps {}

const RangeCalendar: React.FunctionComponent<Props> = (props: Props) => {
	return <RangePicker showTime={false} allowClear={false} format={DEFAULT_FORMAT} {...props} />;
};

export default RangeCalendar;
