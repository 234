import * as React from 'react';
import { Layout as AntdLayout } from 'antd';
import { LayoutProps as AntdLayoutProps } from 'antd/lib/layout';

interface LayoutFooterProps extends AntdLayoutProps {}

const LayoutFooter: React.FC<LayoutFooterProps> = (props: LayoutFooterProps) => {
	return <AntdLayout.Footer {...props} />;
};

export default LayoutFooter;
