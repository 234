import React, { useCallback } from 'react';
import { SharedLocation, SharingMethod } from '@bringg/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import Timezone from '../timezone/timezone';
import DateDisplay from '../date-display/date-display';

interface Props {
	sharedLocations: SharedLocation[];
	timeZone: string;
	dateFormat: string;
	translations: Translations;
}

export interface Translations {
	sharedPhone: string;
	sharedEmail: string;
	sharedApp: string;
	firstSeen: string;
	viewed: string;
	times: string;
	link: string;
}

const TaskTrackingLinks: React.FC<Props> = props => {
	const links = props.sharedLocations.map(sharedLocation => (
		<TaskTrackingLink key={sharedLocation.id} sharedLocation={sharedLocation} {...props} />
	));

	return <div className="task-tracking-links-container">{links}</div>;
};

type TaskTrackingLinkProps = Omit<Props, 'sharedLocations'> & {
	sharedLocation: SharedLocation;
};

const TaskTrackingLink: React.FC<TaskTrackingLinkProps> = ({ sharedLocation, timeZone, dateFormat, translations }) => {
	const getSharingMethodTitle = useCallback((): string => {
		switch (sharedLocation.sharing_method) {
			case SharingMethod.Phone: {
				return translations.sharedPhone;
			}

			case SharingMethod.Email: {
				return translations.sharedEmail;
			}

			case SharingMethod.Websocket: {
				return translations.sharedApp;
			}
		}
	}, [sharedLocation, translations]);

	return (
		<div className="task-tracking-link-container">
			<div className="task-tracking-link-title">
				<FontAwesomeIcon icon={faMapMarkerAlt} /> {getSharingMethodTitle()}
			</div>

			<DateDisplay date={sharedLocation.shared_on} format={dateFormat} />
			<Timezone timezone={timeZone} />

			<div className="task-tracking-link-title">{translations.firstSeen}</div>

			<DateDisplay date={sharedLocation.first_seen_on} format={dateFormat} />
			<Timezone timezone={timeZone} />
			<span className="task-tracking-link-view-counter">
				{translations.viewed} {sharedLocation.view_counter} {translations.times}
			</span>
			<div className="task-tracking-link-url">
				<a href={sharedLocation.url} target="_blank" rel="noopener noreferrer">
					{translations.link}
				</a>
			</div>
		</div>
	);
};

export default TaskTrackingLinks;
