import React, { FunctionComponent } from 'react';
import { Switch, useRouteMatch, Redirect, Route as ReactRouter, Route } from 'react-router-dom';
import PagesHeader from './pages-header/pages-header';
import { find as _find, first as _first, get as _get } from 'lodash';

export interface RouteDetails extends RouteInfo {
	component: React.FunctionComponent<any>;
	exact?: boolean;
	default?: boolean;
}

export interface RouteInfo {
	path: string;
	title?: string;
}

interface Props {
	routes: RouteDetails[];
}

const Pages: FunctionComponent<Props> = ({ routes }) => {
	const { url: path } = useRouteMatch();
	const defaultPath = _find(routes, 'default') || _first(routes);

	return (
		<div className="pages">
			<PagesHeader routes={routes} />
			<Switch>
				<ReactRouter
					exact
					path={path}
					render={({ location }) => (
						<Redirect
							to={{
								pathname: `${path}/${_get(defaultPath, 'path')}`,
								state: { from: location }
							}}
						/>
					)}
				/>
				{routes.map(route => {
					const mergedRoute = { ...route, path: path + '/' + route.path };
					return <Route key={route.path} {...mergedRoute} />;
				})}
			</Switch>
		</div>
	);
};

export default Pages;
