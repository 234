import * as React from 'react';
import { Row as AntdRow } from 'antd';
import { RowProps as AntdRowProps } from 'antd/lib/row';

interface RowProps extends AntdRowProps {}

const Row: React.FC<RowProps> = (props: RowProps) => {
	return <AntdRow {...props} />;
};

export default Row;
