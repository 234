import React from 'react';
import classNames from 'classnames';

interface FillCheckboxProps {
	isChecked: boolean;
	onClick: (newState: boolean, event: React.MouseEvent) => void;
	color: string;
	children: string | React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
}

const FillCheckBox: React.FC<FillCheckboxProps> = ({
	isChecked,
	onClick: clientOnClick,
	color,
	children,
	className,
	style: clientStyle
}) => {
	const style = { ...clientStyle, '--color': color };
	const onClick = event => {
		clientOnClick(!isChecked, event);
	};
	return (
		<div className={classNames('fill-checkbox', className, { fill: isChecked })} style={style} onClick={onClick}>
			{children}
		</div>
	);
};

export default FillCheckBox;
