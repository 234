import * as React from 'react';
import { Avatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';

const AvatarWrapper: React.FunctionComponent<AvatarProps> = (props: AvatarProps) => {
	return (
		<div className="avatar-wrapper">
			<Avatar {...props} />
		</div>
	);
};

AvatarWrapper.defaultProps = {
	size: 'large'
};

export default AvatarWrapper;
