import * as React from 'react';
import Tag from '../tag/tag';
import classNames from 'classnames';
import { CSSProperties } from 'react';

interface LabelProps {
	text?: string;
	className?: string;
	size?: 'default' | 'small';
	style?: CSSProperties;
}

const Label: React.FC<LabelProps> = ({ text, className, size = 'default', style }: LabelProps) => {
	const classes = classNames('bringg-label', size, className);

	return (
		<Tag className={classes} closable={false} style={style}>
			{text}
		</Tag>
	);
};

export default Label;
