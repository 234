import React from 'react';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NodeState } from '../hooks/use-checkbox-states';
import classNames from 'classnames';

export interface Props {
	onChange: (id: string, state: NodeState) => void;
	state: NodeState;
	filled: boolean;
	id: string;
	isDisabled?: boolean;
}

const StateToIcon = {
	[NodeState.full]: faCheck,
	[NodeState.partial]: faMinus,
	[NodeState.empty]: null
};

const Checkbox: React.FC<Props> = ({ onChange, state, filled, id, isDisabled = false }) => {
	const icon = StateToIcon[state];

	const onClick = () => {
		if (isDisabled) return;

		if (state === NodeState.empty) {
			onChange(id, NodeState.full);
		} else if (state === NodeState.partial) {
			onChange(id, NodeState.full);
		} else {
			onChange(id, NodeState.empty);
		}
	};

	return (
		<div className={classNames('tree-checkbox', { filled, disabled: isDisabled })} onClick={onClick}>
			{icon && <FontAwesomeIcon icon={icon} className="icon" />}
		</div>
	);
};

export default Checkbox;
