import React, { CSSProperties, useCallback } from 'react';
import Input from '../floating-placeholder-input/input';
import _toNumber from 'lodash/toNumber';
import classNames from 'classnames';

interface Props {
	value?: number;
	maxValue?: number;
	minValue?: number;
	title?: string;
	onChange?: (value: number) => void;
	style?: CSSProperties;
	className?: string;
}

const NumberInput: React.FC<Props> = ({
	value,
	maxValue = Infinity,
	minValue = -Infinity,
	title,
	onChange,
	style,
	className
}) => {
	const getValue = useCallback(
		(value: number) => {
			if (value < minValue) return minValue;
			if (value > maxValue) return maxValue;
			return value;
		},
		[minValue, maxValue]
	);

	const setValue = useCallback(
		(value: number) => {
			const newValue = getValue(value);
			onChange(newValue);
		},
		[onChange]
	);

	const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = _toNumber(event.target.value);
		setValue(newValue);
	}, []);

	return (
		<div className={classNames('number-input-container', className)} style={style}>
			<Input value={value} onChange={onInputChange} type="number" placeholder={title} />

			<div className="number-input-buttons">
				<div className="number-input-button" onClick={() => setValue(value + 1)}>
					+
				</div>
				<div className="number-input-button" onClick={() => setValue(value - 1)}>
					-
				</div>
			</div>
		</div>
	);
};

export default NumberInput;
