import { useEffect, useState } from 'react';

export const _scrollToElement = elementId => {
	const element = document.getElementById(elementId);
	if (element) element.scrollIntoView({ block: 'center' });
};

const useSearchNextPrevious = (items: string[]) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const activeId = items[activeIndex];

	const next = () => {
		setActiveIndex((activeIndex + 1) % items.length);
	};

	const previous = () => {
		setActiveIndex((((activeIndex - 1) % items.length) + items.length) % items.length);
	};

	useEffect(() => {
		setActiveIndex(0);
	}, [items]);

	useEffect(() => {
		_scrollToElement(activeId);
	}, [activeId]);

	return { next, previous, activeIndex, activeId };
};

export default useSearchNextPrevious;
