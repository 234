import _maxBy from 'lodash/maxBy';
import { Header } from '../csv-uploader.consts';

const CHARSET = 'utf-8';

class CsvTemplateDownloader {
	static mapCsvFieldsToString = (csvFields: Header[]) => {
		const maxIndex = _maxBy(csvFields, 'field_index').field_index;

		const newArray = new Array(maxIndex).fill('');

		csvFields.forEach(field => {
			newArray[field.field_index - 1] = field.field_name;
		});

		return newArray.join(',');
	};

	static createFileValue = (csv: string) => `data:text/csv;charset=${CHARSET},${encodeURI(csv)}`;

	public static downloadCsv = async (fields: Header[], fileName: string) => {
		const csv = CsvTemplateDownloader.mapCsvFieldsToString(fields);

		const link = window.document.createElement('a');
		link.href = CsvTemplateDownloader.createFileValue(csv);
		link.download = `${fileName}.csv`;
		link.click();
		link.remove();
	};
}

export default CsvTemplateDownloader;
