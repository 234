import React, { FunctionComponent, useCallback } from 'react';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { MenuItem } from '../side-menu-container';
import _isNil from 'lodash/isNil';

export interface Props {
	items: MenuItem[];
	collapsed: boolean;
}

const MenuItems: FunctionComponent<Props> = ({ items, collapsed }) => {
	let { path } = useRouteMatch();
	const { pathname } = useLocation();

	const getSelectedKeys = useCallback((): string[] => {
		const pathToSearch = pathname.replace(`/`, '').split('/');
		const currentItem = items.find(item => item.path.toLowerCase() === pathToSearch[0]);

		return _isNil(currentItem) ? [items[0].key] : [currentItem.key];
	}, [path, pathname]);

	return (
		<Menu
			inlineCollapsed={collapsed}
			mode="inline"
			theme="dark"
			defaultSelectedKeys={getSelectedKeys()}
			selectedKeys={getSelectedKeys()}
		>
			{items.map(item => (
				<Menu.Item key={item.key}>
					{collapsed ? (
						<Tooltip title={item.title} placement="right">
							<Link to={`${path}${item.path}`}>
								<FontAwesomeIcon className="menu-icon" icon={item.icon} />
								{item.title}
							</Link>
						</Tooltip>
					) : (
						<Link to={`${path}${item.path}`}>
							<FontAwesomeIcon className="menu-icon" icon={item.icon} />
							<span className="item-title">{item.title}</span>
						</Link>
					)}
				</Menu.Item>
			))}
		</Menu>
	);
};

export default MenuItems;
