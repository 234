import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

interface Props {
	lastUpdated: Date;
	lastUpdatedLabel: string;
	className?: string;
}

export const TIMESTAMP_FORMAT = 'DD/MM/YY, HH:mm:ss';

const LastUpdated: React.FC<Props> = ({ lastUpdated, lastUpdatedLabel, className }) => {
	const lastUpdatedString = moment(lastUpdated).format(TIMESTAMP_FORMAT);

	return (
		<div className={classNames('last-updated', className)}>
			<span className="title">{lastUpdatedLabel}</span>
			<span className="timestamp">{lastUpdatedString}</span>
		</div>
	);
};

export default LastUpdated;
