import React, { FunctionComponent } from 'react';
import { BreadcrumbItemProps } from 'antd/lib/breadcrumb/BreadcrumbItem';
import { Breadcrumb } from 'antd';

export interface BreadcrumbItemExtendedProps extends BreadcrumbItemProps {
	children?: React.ReactNode;
	text?: string;
}

interface Props {
	items: BreadcrumbItemExtendedProps[];
	path?: string;
}

const BreadcrumbsWrapper: FunctionComponent<Props> = ({ items, path }: Props) => {
	return (
		<div className="breadcrumbs-wrapper">
			<Breadcrumb>
				{items
					? items.map((item, index) => {
							if (path && !path.includes(item.href)) {
								return null;
							}
							return (
								<Breadcrumb.Item href={item.href} key={index} onClick={item.onClick}>
									{item.children} {item.text}
								</Breadcrumb.Item>
							);
					  })
					: null}
			</Breadcrumb>
		</div>
	);
};

export default BreadcrumbsWrapper;
