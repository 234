import * as React from 'react';
import { MomentInput } from 'moment';
import moment from 'moment';

interface Props {
	date: MomentInput;
	format?: string;
	invalidDateText?: string;
}

const DateDisplay = class Date extends React.Component<Props> {
	public static defaultProps = {
		invalidDateText: ''
	};
	public render() {
		const { date, format, invalidDateText } = this.props;

		return <span>{date ? moment(date).format(format) : invalidDateText}</span>;
	}
};

export default DateDisplay;
