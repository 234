import { forEach as _forEach, isString as _isString } from 'lodash';
import ReactDOM from 'react-dom';
import {
	Marker as MapboxMarker,
	Popup as MapboxPopup,
	Map as MapboxMap,
	PointLike,
	Layer,
	GeoJSONSource
} from 'mapbox-gl';
import { MapboxMarkerData, MarkerInstance } from '../mapbox';
import { defaultAnchor, IdType, ImageUrl } from '../../../types/maps.consts';

type IdsMap = { [key: number]: boolean };

export const updateMarkers = (markers: MapboxMarkerData[], markersMap: Map<IdType, MarkerInstance>, map: MapboxMap) => {
	const markersIds = {};

	_forEach(markers, marker => {
		const { id } = marker;
		markersIds[id] = true;
		addOrUpdateMarker(marker, markersMap, map);
	});

	removeOldMarkers(markersIds, markersMap, map);
};

export const isMarkerImage = (marker: MapboxMarkerData): boolean => {
	return _isString(marker.icon);
};

const addOrUpdateMarker = (marker: MapboxMarkerData, markersMap: Map<IdType, MarkerInstance>, map: MapboxMap) => {
	if (isMarkerImage(marker)) {
		addOrUpdateImageMarker(marker, markersMap, map);
	} else {
		addOrUpdateMapboxMarker(marker, markersMap, map);
	}
};

const addOrUpdateMapboxMarker = (marker: MapboxMarkerData, markersMap: Map<IdType, MarkerInstance>, map: MapboxMap) => {
	const { id, location, popup } = marker;
	const anchor = marker.anchor || defaultAnchor;
	const { lng, lat } = location;
	const icon = marker.icon as JSX.Element;

	if (markersMap.has(id)) {
		removeMarker(id, markersMap, map);
	}

	let iconElement;

	if (icon) {
		iconElement = document.createElement('div');
		ReactDOM.render(icon, iconElement);
	}

	const markerInstance: MarkerInstance = {
		id,
		mapboxMarker: new MapboxMarker(iconElement, { anchor }).setLngLat([lng, lat]).addTo(map)
	};
	markersMap.set(id, markerInstance);

	if (popup) {
		const mapboxPopup = createMapboxPopup(marker, markersMap);
		markersMap.get(marker.id).mapboxMarker.setPopup(mapboxPopup);
	}
};

const createMapboxPopup = (marker: MapboxMarkerData, markersMap: Map<IdType, MarkerInstance>): MapboxPopup => {
	const { id, popup } = marker;
	const { element, anchor } = popup;
	const popupTemplate = document.createElement('div');
	let offset: PointLike;

	const currentPopup = markersMap.get(id).mapboxPopup;

	if (currentPopup) {
		currentPopup.remove();
	}

	if (popup.offset) {
		const { x, y } = popup.offset;
		offset = [x, y];
	}

	const mapboxPopup = new MapboxPopup({ closeButton: false, offset, anchor })
		.on('open', event => {
			ReactDOM.render(element, popupTemplate, () => {
				event.target.setDOMContent(popupTemplate);
			});
		})
		.on('close', () => {
			ReactDOM.unmountComponentAtNode(popupTemplate);
		});

	markersMap.get(id).mapboxPopup = mapboxPopup;

	return mapboxPopup;
};

const addOrUpdateImageMarker = (marker: MapboxMarkerData, markersMap: Map<IdType, MarkerInstance>, map: MapboxMap) => {
	const { id, location, popup, text } = marker;
	const { lng, lat } = location;
	const image = marker.icon as ImageUrl;
	const anchor = marker.anchor || defaultAnchor;

	const currentMarker = markersMap.get(id);

	if (currentMarker && currentMarker.mapboxMarker) {
		removeMarker(id, markersMap, map);
	}

	const markerInstance = {
		id,
		layerId: `layer-${id}`,
		sourceId: `source-${id}`
	};

	const data: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
		type: 'FeatureCollection',
		features: [
			{
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'Point',
					coordinates: [lng, lat]
				}
			}
		]
	};

	const currentSource = map.getSource(markerInstance.sourceId) as GeoJSONSource;

	if (currentSource) {
		currentSource.setData(data);
	} else {
		map.addSource(markerInstance.sourceId, {
			type: 'geojson',
			data
		});
	}

	const currentLayer = map.getLayer(markerInstance.layerId);

	if (!currentLayer) {
		const layer: Layer = {
			id: markerInstance.layerId,
			type: 'symbol',
			source: markerInstance.sourceId,
			layout: {
				'icon-size': 1,
				'icon-allow-overlap': true,
				'icon-anchor': anchor
			}
		};

		if (text) {
			Object.assign(layer.layout, {
				'text-font': ['Roboto Black'],
				'text-size': 13,
				'text-allow-overlap': true
			});
		}

		map.addLayer(layer);
	}

	map.setLayoutProperty(markerInstance.layerId, 'icon-image', `image-${image}`);

	if (text) {
		map.setLayoutProperty(markerInstance.layerId, 'text-field', text);
	}

	markersMap.set(id, markerInstance);

	if (popup) {
		addImageMarkerPopup(marker, markerInstance.layerId, markersMap, map);
	}
};

const addImageMarkerPopup = (
	marker: MapboxMarkerData,
	layerId: string,
	markersMap: Map<IdType, MarkerInstance>,
	map: MapboxMap
) => {
	const { location } = marker;
	map.on('click', layerId, () => {
		const mapboxPopup = createMapboxPopup(marker, markersMap);
		mapboxPopup.setLngLat(location).addTo(map);
	});
};

const removeMarker = (markerId: IdType, markersMap: Map<IdType, MarkerInstance>, map: MapboxMap) => {
	const markerInstance = markersMap.get(markerId);

	if (markerInstance.mapboxMarker) {
		markerInstance.mapboxMarker.remove();
	} else {
		map.removeLayer(markerInstance.layerId);
		map.removeSource(markerInstance.sourceId);
	}

	if (markerInstance.mapboxPopup) {
		markerInstance.mapboxPopup.remove();
	}

	markersMap.delete(markerId);
};

const removeOldMarkers = (markersIds: IdsMap, markersMap: Map<IdType, MarkerInstance>, map: MapboxMap) => {
	for (const key of markersMap.keys()) {
		if (!markersIds[key]) {
			removeMarker(key, markersMap, map);
		}
	}
};
