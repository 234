import _filter from 'lodash/filter';
import _debounce from 'lodash/debounce';
import { getDescendants } from '../../../../tree-utils';
import { useEffect, useState } from 'react';
import { TreeModel } from '../../../../tree';

const _findNodes = (tree, nodeId, text) => {
	const allNodeIds = [nodeId, ...getDescendants(tree, nodeId)];
	return _filter(allNodeIds, nodeId => tree[nodeId].option.includes(text));
};

const delay = 300;

const debouncedSet = _debounce((set, search) => {
	set(search());
}, delay);

const useMatchingNodes = (tree: TreeModel, nodeId: string, text: string) => {
	const [matchingNodes, setMatchingNodes] = useState<string[]>([]);

	useEffect(() => {
		if (text === '') {
			setMatchingNodes([]);
		} else {
			debouncedSet(setMatchingNodes, () => _findNodes(tree, nodeId, text));
			return debouncedSet.cancel;
		}
	}, [tree, nodeId, text]);

	return matchingNodes;
};

export default useMatchingNodes;
