import React, { CSSProperties } from 'react';
import classNames from 'classnames';

interface Props {
	capacity?: number;
	maxCapacity?: number;
	fullTextClassName?: string;
	className?: string;
	style?: CSSProperties;
}

const CapacityMetric = ({ capacity = 0, maxCapacity = 0, className, style }: Props) => {
	const isDeliveryBlockAvailable = capacity < maxCapacity || maxCapacity === 0;
	const mergedClass = classNames('capacity-counter', className);
	return (
		<div className={mergedClass} style={style}>
			<span className={isDeliveryBlockAvailable ? 'capacity-counter-available' : 'capacity-counter-full'}>
				{capacity}
			</span>

			<span
				className={classNames(
					'capacity-counter-block-capacity',
					isDeliveryBlockAvailable ? 'capacity-counter-available' : 'capacity-counter-full'
				)}>
				{`/${maxCapacity}`}
			</span>
		</div>
	);
};

export default CapacityMetric;
