import React, { useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl, { Map as MapboxMap, Marker as MapboxMarker, NavigationControl, Popup as MapboxPopup } from 'mapbox-gl';
import { isFunction as _isFunction, map as _map, uniq as _uniq, compact as _compact } from 'lodash';
import { isMarkerImage, updateMarkers } from './utils/markers-manager';
import { updateImages } from './utils/images-manager';
import { BringgMapProps, IdType, ImageUrl, MarkerData, Popup } from '../../types/maps.consts';

export type MapboxImage = string;

export interface MarkerInstance {
	id: IdType;
	mapboxMarker?: MapboxMarker;
	mapboxPopup?: MapboxPopup;
	layerId?: string;
	sourceId?: string;
}

type MapboxProps = BringgMapProps<MapboxMap> & {
	mapboxOptions: {
		style: string;
	};
	markers?: MapboxMarkerData[];
};

export type MapboxMarkerData = MarkerData & {
	popup?: Popup;
};

const Mapbox: React.FC<MapboxProps> = ({
	mapboxOptions,
	apiKey,
	zoom,
	zoomControl,
	center,
	onLoaded,
	markers,
	className,
	children
}) => {
	const { lng, lat } = center;
	const { style } = mapboxOptions;
	const [map, setMap] = useState<MapboxMap>();
	const markersMap: Map<IdType, MarkerInstance> = useMemo(() => new Map(), []);
	const imagesMap: Map<ImageUrl, MapboxImage> = useMemo(() => new Map(), []);
	const refContainer = useRef();

	useEffect(() => {
		if (mapboxgl.getRTLTextPluginStatus() === 'unavailable') {
			mapboxgl.setRTLTextPlugin(
				'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
				null,
				true
			);
		}

		const map = new MapboxMap({
			accessToken: apiKey,
			style,
			container: refContainer.current,
			center: [lng, lat],
			zoom
		});

		if (zoomControl) {
			map.addControl(
				new NavigationControl({
					showCompass: false,
					showZoom: true
				}),
				'top-left'
			);
		}

		map.on('load', () => {
			setMap(map);

			if (_isFunction(onLoaded)) {
				onLoaded(map);
			}
		});
	}, []);

	useEffect(() => {
		if (!map) {
			return;
		}

		map.setZoom(zoom);
		map.setCenter(center);
	}, [map, zoom, center]);

	useEffect(() => {
		if (!map) {
			return;
		}

		const images: ImageUrl[] = _compact(
			_uniq(
				_map(markers, marker => {
					if (isMarkerImage(marker)) {
						return marker.icon as ImageUrl;
					}
				})
			)
		);

		const updateImagesAndMarkers = async () => {
			await updateImages(images, imagesMap, map);
			updateMarkers(markers, markersMap, map);
		};

		updateImagesAndMarkers();
	}, [map, markers]);

	return (
		<div ref={refContainer} className={className}>
			{children}
		</div>
	);
};

Mapbox.defaultProps = {
	zoomControl: true
};

export default Mapbox;
