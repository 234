import { Map as MapboxMap } from 'mapbox-gl';
import { forEach as _forEach } from 'lodash';
import { ImageUrl } from '../../../types/maps.consts';
import { MapboxImage } from '../mapbox';

type IdsMap = { [key: string]: boolean };

export const updateImages = (
	images: ImageUrl[],
	imagesRenderState: Map<ImageUrl, MapboxImage>,
	map: MapboxMap
): Promise<void[]> => {
	const imagesIds = {};
	const addImagesPromises: Promise<void>[] = [];

	_forEach(images, image => {
		imagesIds[image] = true;

		if (!imagesRenderState.has(image)) {
			const addImagesPromise = addImage(image, imagesRenderState, map);
			addImagesPromises.push(addImagesPromise);
		}
	});

	removeOldImages(imagesIds, imagesRenderState, map);

	return Promise.all(addImagesPromises);
};

const addImage = (image: ImageUrl, imagesRenderState: Map<ImageUrl, MapboxImage>, map: MapboxMap): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		map.loadImage(image, (error, imageObject) => {
			if (error) {
				reject();
			}

			const mapboxImage = `image-${image}`;
			map.addImage(mapboxImage, imageObject);
			imagesRenderState.set(image, mapboxImage);
			resolve();
		});
	});
};

const removeImage = (image: ImageUrl, imagesRenderState: Map<ImageUrl, MapboxImage>, map: MapboxMap) => {
	map.removeImage(imagesRenderState.get(image));
	imagesRenderState.delete(image);
};

const removeOldImages = (imagesIds: IdsMap, imagesRenderState: Map<ImageUrl, MapboxImage>, map: MapboxMap) => {
	for (const key of imagesRenderState.keys()) {
		if (!imagesIds[key]) {
			removeImage(key, imagesRenderState, map);
		}
	}
};
