import * as React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';

interface Props extends CheckboxProps {}

const Checkbox: React.FunctionComponent<Props> = (props: Props) => {
	const { children, ...otherProps } = props;

	return <AntdCheckbox {...otherProps}>{children}</AntdCheckbox>;
};

export default Checkbox;
