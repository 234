import React from 'react';
import { Avatar } from 'antd';
import classNames from 'classnames';

interface Props {
	user: Partial<Bringg.User>;
	className?: string;
}

const UserAvatarInfo = ({ user, className }: Props) => {
	return (
		<div className={classNames('user-info', className)}>
			<Avatar src={user.profile_image} />
			<div className="user-info-name">{user.name}</div>
		</div>
	);
};

export default UserAvatarInfo;
