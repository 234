import * as React from 'react';
import Spinner from '../spinner/spinner';
import classNames from 'classnames';

export type Props = {
	isFetched: boolean;
	children: JSX.Element[] | JSX.Element;
	displayChildrenAndSpinner?: boolean;
	hideSpinner?: boolean;
	className?: string;
};

const getFetchedDataSpinner = (hideSpinner = false, className?) => {
	return !hideSpinner ? <Spinner className={className} /> : null;
};

const FetchedData = ({ isFetched, children, hideSpinner, displayChildrenAndSpinner, className }: Props) => {
	const mergedClass = classNames(
		className,
		displayChildrenAndSpinner ? 'fetched-data-display-all' : 'fetched-data-spinner'
	);

	if (displayChildrenAndSpinner) {
		return (
			<>
				{!isFetched ? getFetchedDataSpinner(hideSpinner, mergedClass) : null}
				{children}
			</>
		);
	}

	return <>{isFetched ? children : getFetchedDataSpinner(hideSpinner, mergedClass)}</>;
};

export default FetchedData;
