import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import React, { useCallback } from 'react';
import { UploadChangeParam } from 'antd/lib/upload';
import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-solid-svg-icons/faFile';

export interface DragAndDropProps extends DraggerProps {
	onFileUploaded: (file: Blob) => void;
	icon?: JSX.Element;
	text?: string;
	body?: JSX.Element;
}

const DefaultText = 'Click or drag in order to upload file';
const DefaultIcon = <FontAwesomeIcon icon={faFile} size={'4x'} />;

const dummyRequest = ({ onSuccess }) => {
	setTimeout(() => {
		onSuccess('ok');
	}, 0);
};

const DragAndDrop: FunctionComponent<DragAndDropProps> = ({
	onFileUploaded,
	icon = DefaultIcon,
	text = DefaultText,
	body,
	...otherProps
}: DragAndDropProps) => {
	const fileChangedHandler = useCallback(
		(uploadResponse: UploadChangeParam) => {
			if (uploadResponse.file.status === 'done') {
				onFileUploaded(uploadResponse.file.originFileObj);
			}
		},
		[onFileUploaded]
	);

	return (
		<Dragger onChange={fileChangedHandler} customRequest={dummyRequest} {...otherProps}>
			<p className="ant-upload-drag-icon">{icon}</p>
			<p className="ant-upload-text">{text}</p>
			{body}
		</Dragger>
	);
};

export default DragAndDrop;
