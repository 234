import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import _isEmpty from 'lodash/isEmpty';

export type OptionWrapper = {
	id: string | number;
	name: string;
	dropDownKey?: string;
};

interface Props extends SelectProps {
	onSelect?: (value: any) => void; // this is for not casting value in all the places
	onDeselect?: (value: any) => void; // this is for not casting value in all the places
	onChange?: (value: any) => void; // this is for not casting value in all the places;
	children?: React.ReactNode;
	ref?: any;
	options?: OptionWrapper[];
}

const Option = Select.Option;

const getWrapperOptions = (options: OptionWrapper[]) => {
	return options.map(({ id, name, dropDownKey }) => (
		<Option key={id} value={id} data-testid={`${dropDownKey}-dropdown-option`}>
			{name}
		</Option>
	));
};

class SelectWrapper extends React.PureComponent<Props> {
	static defaultProps = {
		mode: 'default'
	};

	render() {
		const { children, options, ...selectProps } = this.props;

		return (
			<div className="select-wrapper">
				{!_isEmpty(options) ? (
					<Select {...selectProps}>{getWrapperOptions(options)}</Select>
				) : (
					<Select {...selectProps}>{children}</Select>
				)}
			</div>
		);
	}
}

export default SelectWrapper;

export { Option };
