import * as React from 'react';
import { Radio as AntdRadio } from 'antd';
import { RadioProps } from 'antd/es/radio';

export const RadioGroup = AntdRadio.Group;
export const RadioButton = AntdRadio.Button;

interface Props extends RadioProps {}

const Radio: React.FunctionComponent<Props> = (props: Props) => {
	return <AntdRadio {...props} />;
};

export default Radio;
