import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToTop } from '@fortawesome/pro-solid-svg-icons/faArrowToTop';
import { faFileImport } from '@fortawesome/pro-solid-svg-icons/faFileImport';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import {
	CsvUploadResult,
	DEFAULT_LINES_IMPORTED_TEXT,
	DEFAULT_LINES_UPLOADED_TEXT,
	DEFAULT_LINES_WITH_ERRORS_TEXT
} from '../csv-uploader.consts';
import _isEmpty from 'lodash/isEmpty';

interface Props {
	results: CsvUploadResult;
	linesUploadedText?: string;
	linesImportedText?: string;
	linesWithErrorsText?: string;
}

const uploadedIcon = <FontAwesomeIcon icon={faArrowToTop} className="icon uploaded-icon" />;
const errorIcon = <FontAwesomeIcon icon={faExclamationTriangle} className="icon error-icon" />;
const importedIcon = <FontAwesomeIcon icon={faFileImport} className="icon imported-icon" />;

const IconLine = (icon: JSX.Element, text: string) => {
	return (
		<div className="icon-line">
			<span className="icon-container">{icon}</span>
			<span>{text}</span>
		</div>
	);
};

const ResultsView: React.FC<Props> = ({
	results,
	linesImportedText = DEFAULT_LINES_IMPORTED_TEXT,
	linesUploadedText = DEFAULT_LINES_UPLOADED_TEXT,
	linesWithErrorsText = DEFAULT_LINES_WITH_ERRORS_TEXT
}) => {
	return (
		<div className="results-view">
			{IconLine(uploadedIcon, `${linesUploadedText} ${results.uploaded_number}`)}
			{IconLine(importedIcon, `${linesImportedText} ${results.imported_number}`)}
			{IconLine(errorIcon, `${linesWithErrorsText} ${results.failed_number}`)}
			{!_isEmpty(results.errors) && (
				<div className="errors-container">
					{results.errors.map((error, index) => (
						<span key={index}>{error}</span>
					))}
				</div>
			)}
		</div>
	);
};

export default ResultsView;
