import * as React from 'react';
import { Col as AntdCol } from 'antd';
import { ColProps as AntdColProps } from 'antd/lib/col';

interface ColProps extends AntdColProps {}

const Col: React.FC<ColProps> = (props: ColProps) => {
	return <AntdCol {...props} />;
};

export default Col;
