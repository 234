import React, { CSSProperties } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import classname from 'classnames';

interface RowRendererProps {
	index: number;
	isScrolling: boolean;
	isVisible: boolean;
	key: string;
	parent: Object;
	style: Object;
}

export interface ItemRendererProps {
	item: any;
	isScrolling: boolean;
	isVisible: boolean;
	index: number;
	className: string;
}

export type ItemRenderer = (props: Partial<ItemRendererProps>) => JSX.Element;

export interface Props {
	listHeight?: number;
	flex?: boolean;
	rowHeight: number;
	itemRenderer: ItemRenderer;
	items: any[];
	className?: string;
	rowClass?: string;
}

function getRowRenderer<T>(
	itemRenderer: ItemRenderer,
	items: T[],
	rowClass: string
): (props: RowRendererProps) => JSX.Element {
	return function({ key, index, isScrolling, isVisible, style }) {
		return (
			<div key={key} style={style} className={classname(rowClass)}>
				{itemRenderer({ item: items[index], index, isScrolling, isVisible })}
			</div>
		);
	};
}

const VirtualList = ({ flex, listHeight, rowHeight, itemRenderer, items, className, rowClass }: Props) => {
	const renderer = getRowRenderer(itemRenderer, items, rowClass);
	const defaultStyle = { outline: 'none' };

	let style: CSSProperties = {};
	if (flex) {
		style.flex = '1 1 auto';
	}
	if (listHeight) {
		style.height = listHeight;
	}
	if (flex && listHeight) {
		throw new Error('Please choose between flex/listHeight props');
	}

	return (
		<div style={style} className={classname(className)}>
			<AutoSizer>
				{({ width, height }) => (
					<List
						style={defaultStyle}
						width={width}
						height={height}
						rowCount={items.length}
						rowHeight={rowHeight}
						rowRenderer={renderer}
					/>
				)}
			</AutoSizer>
		</div>
	);
};

export default VirtualList;
