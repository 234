import classNames from 'classnames';
import React from 'react';

interface Props {
	start: number;
	end: number;
	text: string;
	id: string;
	isActive: boolean;
}

const MatchedNode: React.FC<Props> = ({ start, end, text, id, isActive }) => {
	const preMatch = text.slice(0, start);
	const match = text.slice(start, end);
	const postMatch = text.slice(end, text.length);

	return (
		<span className="matched-node">
			<span className="pre-match" id={id}>
				{preMatch}
			</span>
			<span className={classNames('match', { active: isActive })}>{match}</span>
			<span className="post-match">{postMatch}</span>
		</span>
	);
};

export default MatchedNode;
