import React, { useCallback } from 'react';
import { Collapse as AntdCollapse } from 'antd';
import { CollapsePanelProps, CollapseProps as AntdCollapseProps } from 'antd/lib/collapse';
import classname from 'classnames';
import { isNil, isEmpty } from 'lodash';

interface CollapseProps extends CollapsePanelProps {
	isOpen?: boolean;
	defaultKey?: string;
	onChange?: (keys) => void;
}

const DEFAULT_KEY = 'bringg-collapse-panel';

const getCollapseProps = (isOpen: boolean, key: string | number): Partial<AntdCollapseProps> => {
	if (isNil(isOpen)) {
		return {
			defaultActiveKey: key
		};
	}

	return {
		activeKey: isOpen ? key : null
	};
};

const Collapse: React.FC<CollapseProps> = (props: CollapseProps) => {
	const key = props.defaultKey || DEFAULT_KEY;
	const { className, isOpen, onChange: clientOnChange, ...otherProps } = props;

	const onChange = useCallback(keys => {
		if (clientOnChange) {
			clientOnChange(!isEmpty(keys));
		}
	}, []);

	return (
		<div className={`bringg-collapse ${classname(className)}`}>
			<AntdCollapse bordered={false} {...getCollapseProps(isOpen, key)} onChange={onChange}>
				<AntdCollapse.Panel {...otherProps} key={key} />
			</AntdCollapse>
		</div>
	);
};

export default Collapse;
