import React, { FunctionComponent, useCallback } from 'react';
import logo from '../../../images/logo.png';
import { Layout } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faArrowFromLeft } from '@fortawesome/pro-solid-svg-icons/faArrowFromLeft';
import { MenuItem } from '../side-menu-container';
import MenuItems from '../menu-items/menu-items';

const { Header, Content, Footer } = Layout;

export interface Props {
	items: MenuItem[];
	onCollapseChanged: (collapsed: boolean) => void;
	onLogout: () => void;
	userName: string;
	userEmail: string;
	logoutText: string;
	collapsed: boolean;
}

const SideMenu: FunctionComponent<Props> = ({
	items,
	onCollapseChanged,
	onLogout,
	userEmail,
	userName,
	logoutText,
	collapsed
}) => {
	const closeMenu = useCallback(() => {
		onCollapseChanged(true);
	}, []);

	const openMenu = useCallback(() => {
		onCollapseChanged(false);
	}, []);

	const getHeader = useCallback(() => {
		return collapsed ? (
			<FontAwesomeIcon className="open-icon" icon={faBars} onClick={openMenu} />
		) : (
			<>
				<FontAwesomeIcon className="close-icon" icon={faTimes} onClick={closeMenu} />
				<img className="logo" src={logo} alt="" />
			</>
		);
	}, [collapsed]);

	const getFooter = useCallback(() => {
		return collapsed ? (
			<FontAwesomeIcon className="open-icon" icon={faArrowFromLeft} onClick={openMenu} />
		) : (
			<>
				<div className="user-name">{userName}</div>
				<div className="user-email">{userEmail}</div>
				<div className="logout" onClick={onLogout}>
					{logoutText}
				</div>
			</>
		);
	}, [collapsed]);

	return (
		<Layout className={`side-menu ${collapsed ? 'side-menu-collapsed' : ''}`}>
			<Header className="menu-header">{getHeader()}</Header>
			<Content>
				<MenuItems items={items} collapsed={collapsed} />
			</Content>
			<Footer className="menu-footer">{getFooter()}</Footer>
		</Layout>
	);
};

export default SideMenu;
