import React from 'react';
import Card from '../card/card-wrapper';
import Button from '../button/button';

interface Props {
	title?: string | JSX.Element;
	subtitle?: string | JSX.Element;
	description?: string | JSX.Element;
	onClick?: () => void;
	buttonText?: string;
}

interface State {
	hasError: boolean;
}

class ErrorBoundaryPage extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	static defaultProps = {
		title: (
			<>
				Oops...
				<br />
				something went wrong!
			</>
		),
		subtitle: (
			<>
				The page you’ve requested could not be loaded at this time.
				<br />
				Please try again later.
			</>
		),
		buttonText: 'Go Back'
	};

	render() {
		if (!this.state.hasError) {
			return this.props.children;
		}

		const { title, subtitle, description, onClick, buttonText } = this.props;

		return (
			<div className="error-page">
				<Card
					className="error-card"
					children={
						<>
							<h1 className="title">{title}</h1>
							<h2>{subtitle}</h2>
							{description && <h4>{description}</h4>}
							{onClick && (
								<Button onClick={onClick} className="action-button" type="danger" size="large">
									{buttonText}
								</Button>
							)}
						</>
					}
				/>
			</div>
		);
	}
}

export default ErrorBoundaryPage;
