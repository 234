import * as React from 'react';
import { GoogleMap, Marker, Polyline, withGoogleMap, WithGoogleMapProps } from 'react-google-maps';
import { WayPointLocation } from '../../types/common.consts';

import marker from '../../icons/marker.svg';
import driver from '../../icons/driver.svg';
import base from '../../icons/base.svg';

const MAP_DEFAULT_ZOOM = 13;
const MAP_MAIN_COLOR = '#448fff';
const FONT_WEIGHT = '500';
const FONT_SIZE = '18px';

interface MapProps extends WithGoogleMapProps {
	path: google.maps.LatLng[];
	center: google.maps.LatLng;
	wayPointsLocations: WayPointLocation[];
	location: google.maps.LatLng;
	base?: google.maps.LatLng;
	zoom?: number;
}

const Map: React.FunctionComponent<MapProps> = (props: MapProps) => (
	<GoogleMap
		defaultZoom={props.zoom || MAP_DEFAULT_ZOOM}
		defaultCenter={{ lat: props.center.lat(), lng: props.center.lng() }}>
		{props.path && <Polyline options={{ strokeColor: MAP_MAIN_COLOR }} path={props.path} />}
		{props.wayPointsLocations &&
			props.wayPointsLocations.map(wayPoint => {
				return (
					<Marker
						label={{
							text: `${wayPoint.wayPointPosition}`,
							color: MAP_MAIN_COLOR,
							fontWeight: FONT_WEIGHT,
							fontSize: FONT_SIZE
						}}
						icon={marker}
						key={`${wayPoint.id}`}
						position={{
							lat: wayPoint.location.lat(),
							lng: wayPoint.location.lng()
						}}
					/>
				);
			})}
		{props.base && <Marker icon={base} position={{ lat: props.base.lat(), lng: props.base.lng() }} />}
		{props.location && <Marker icon={driver} position={{ lat: props.location.lat(), lng: props.location.lng() }} />}
	</GoogleMap>
);

const MapWrapper = withGoogleMap(Map);

export default MapWrapper;
