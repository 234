export const DEFAULT_FAILED_INIT_TITLE = 'Failed to load';
export const DEFAULT_FAILED_INIT_DESCRIPTION = 'Failed to load initial data. Refresh the page and try again';

export const DEFAULT_FAILED_SUBMIT_TITLE = 'Failed to save changes';
export const DEFAULT_FAILED_SUBMIT_DESCRIPTION = 'Failed to save. Try to save changes again';

export const SAVE_GUARD_TITLE = 'Leave page?';
export const SAVE_GUARD_DESCRIPTION = 'You have unsaved changes. Are you sure you want to leave the page?';
export const SAVE_GUARD_OK_BUTTON = 'Leave This Page';
export const SAVE_GUARD_CANCEL_BUTTON = 'Stay on This Page';

export const REFRESH_DEFAULT_TEXT = 'Please Refresh';
