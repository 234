import * as React from 'react';
import { Badge as AntdBadge } from 'antd';
import { BadgeProps as AntdBadgeProps } from 'antd/lib/badge';

interface BadgeProps extends AntdBadgeProps {}

const Badge: React.FC<BadgeProps> = (props: BadgeProps) => {
	return <AntdBadge {...props} />;
};

export default Badge;
