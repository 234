import * as React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import _isFunction from 'lodash/isFunction';
import _isNil from 'lodash/isNil';
import Button from '../button/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import classNames from 'classnames';

//NOTE: remove when this PR is released - https://github.com/agilgur5/react-signature-canvas/pull/25
interface SignatureCanvasProps {
	dotSize?: number | (() => number);
	minWidth?: number;
	maxWidth?: number;
	minDistance?: number;
	backgroundColor?: string;
	penColor?: string;
	throttle?: number;
	velocityFilterWeight?: number;
	onBegin?: (event: MouseEvent | Touch) => void;
	onEnd?: (event: MouseEvent | Touch) => void;
}

interface Props extends SignatureCanvasProps {
	title?: string;
	value?: string;
	onChange?: (value: string) => void;
	disabled?: boolean;
	disclaimer?: string;
	mandatory?: boolean;
	id?: string;
}

interface State {
	signature: string;
}

const SIGNATURE_WHITE_BACKGROUND = 'rgb(255, 255, 255)';
const SIGNATURE_MIME_TYPE = 'image/jpeg';

class Signature extends React.PureComponent<Props, State> {
	signatureCanvasRef: any = null;

	state = {
		signature: ''
	};

	static getDerivedStateFromProps(nextProps: Readonly<Props>) {
		if ('value' in nextProps) {
			return {
				signature: nextProps.value || ''
			};
		}

		return null;
	}

	componentDidMount() {
		if (this.props.value) {
			this.loadSignatureFromBase64(this.state.signature);
		}
	}

	componentDidUpdate() {
		if (!this.props.value && !this.isSignatureCleared()) {
			this.clearCanvas();
		}
	}

	clearCanvas = () => {
		this.signatureCanvasRef.clear();
		this.triggerChange('');
	};

	isSignatureCleared = () => Boolean(this.signatureCanvasRef && this.signatureCanvasRef.isEmpty());

	getSignatureBase64 = () => this.signatureCanvasRef.toDataURL(SIGNATURE_MIME_TYPE);

	saveSignatureCanvasRef = (signatureCanvasRef: any) => (this.signatureCanvasRef = signatureCanvasRef);

	loadSignatureFromBase64 = (signature: string) => this.signatureCanvasRef.fromDataURL(signature);

	handleSignatureChange = () => {
		const signature = this.getSignatureBase64();

		if (_isNil(signature)) {
			return;
		}

		if (!('value' in this.props)) {
			return this.setState({ signature });
		}

		this.triggerChange(signature);
	};

	triggerChange = (signature: string) => {
		const { onChange } = this.props;

		if (_isFunction(onChange)) {
			onChange(signature);
		}
	};

	render() {
		const { handleSignatureChange, saveSignatureCanvasRef, clearCanvas } = this;
		const { disabled, disclaimer, title, mandatory, id } = this.props;
		const { signature } = this.state;

		return (
			<div className="signature-container">
				{title && (
					<div
						className={classNames('dynamic-form-item-title', { 'dynamic-form-item-mandatory': mandatory })}>
						{title}
					</div>
				)}

				<div className="signature-content">
					<p className="signature-disclaimer">{disclaimer}</p>
					{disabled ? (
						<img src={signature} alt="signature" />
					) : (
						<SignatureCanvas
							ref={saveSignatureCanvasRef}
							onEnd={handleSignatureChange}
							backgroundColor={SIGNATURE_WHITE_BACKGROUND}
							canvasProps={{ className: 'signature-canvas', id }}
						/>
					)}
					{disabled ? (
						<></>
					) : (
						<div className="signature-clear-button">
							<Button onClick={clearCanvas} data-testid="signature-clear-button">
								<FontAwesomeIcon icon={faTrash} />
							</Button>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Signature;
