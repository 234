import { notification } from 'antd';
import { ReactNode } from 'react';
import { ArgsProps, ConfigProps } from 'antd/lib/notification';

const defaultOptions = {
	placement: 'bottomRight',
	duration: 2
} as ConfigProps;

class Notification {
	setOptions = (options: ConfigProps): void => {
		notification.config(options);
	};

	// Don't delete class name ! for automation use
	success = (message?: ReactNode, description?: ReactNode, options?: ArgsProps): void => {
		return notification.success({ className: 'success-notify', ...options, message, description });
	};

	error = (message?: ReactNode, description?: ReactNode, options?: ArgsProps): void => {
		return notification.error({ className: 'error-notify', ...options, message, description });
	};

	info = (message?: ReactNode, description?: ReactNode, options?: ArgsProps): void => {
		return notification.info({ className: 'info-notify', ...options, message, description });
	};

	warn = (message?: ReactNode, description?: ReactNode, options?: ArgsProps): void => {
		return notification.warn({ className: 'warn-notify', ...options, message, description });
	};
}

const bringgNotification = new Notification();
bringgNotification.setOptions(defaultOptions);

export default bringgNotification;
