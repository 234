import React, { useEffect, useMemo } from 'react';
import Label from '../label/label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcodeScan } from '@fortawesome/pro-light-svg-icons/faBarcodeScan';
import { faCoins } from '@fortawesome/pro-light-svg-icons/faCoins';
import { faCommentAlt } from '@fortawesome/pro-light-svg-icons/faCommentAlt';
import { faRuler } from '@fortawesome/pro-light-svg-icons/faRuler';
import { faWeight } from '@fortawesome/pro-light-svg-icons/faWeight';
import { Inventory, TaskInventory as TaskInventoryType } from '@bringg/types';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import Modal from '../modal/modal';
import TaskInventoryForm, { TASK_INVENTORY_CONSTANTS } from './task-inventory-form/task-inventory-form';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import NumberInput from '../number-input/number-input';
import _noop from 'lodash/noop';
import Input from '../floating-placeholder-input/input';
import { Controller, UseFormMethods } from 'react-hook-form';

export interface Translations {
	collectText: string;
	collectedText: string;
	deliverText: string;
	deliveredText: string;
	rejectedText: string;
	editItem: string;
	quantity: string;
	trashItem: string;
	width: string;
	height: string;
	inventoryLength: string;
	save: string;
	cancel: string;
}

interface TaskInventoryProps {
	parentTaskInventory: TaskInventoryType;
	taskInventories?: TaskInventoryType[];
	isInEditMode?: boolean;
	form?: UseFormMethods;
	onQuantityChange?: (massQuantityUpdate: TaskInventoryType[]) => void;
	onInventoriesChange?: (inventories: Inventory[]) => void;
	translations: Translations;
}

const getNumericFieldAsString = (object: any, path: string): string => {
	const field = _get(object, path);
	if (_isNil(field)) {
		return '(N/A)';
	}

	return field;
};

const getTaskInventoryDimensions = (taskInventory: TaskInventoryType): string => {
	return `${getNumericFieldAsString(taskInventory.inventory, 'width')} x ${getNumericFieldAsString(
		taskInventory.inventory,
		'height'
	)} x ${getNumericFieldAsString(taskInventory.inventory, 'length')}`;
};

const TaskInventory: React.FC<TaskInventoryProps> = ({
	parentTaskInventory,
	taskInventories,
	isInEditMode = false,
	form,
	onQuantityChange = _noop,
	onInventoriesChange = _noop,
	translations
}) => {
	const [rootTaskInventory, setRootTaskInventory] = useState(parentTaskInventory);

	useEffect(() => {
		setRootTaskInventory(parentTaskInventory);
	}, [parentTaskInventory]);

	const TASK_INVENTORY_ITEMS = useMemo(
		() => [
			{
				content: getNumericFieldAsString(rootTaskInventory.inventory, 'notes'),
				className: 'task-inventory-notes',
				icon: faCommentAlt
			},
			{
				content: getTaskInventoryDimensions(rootTaskInventory),
				className: 'task-inventory-dimensions',
				tooltip: `${translations.width} x ${translations.height} x ${translations.inventoryLength}`,
				icon: faRuler
			},
			{
				content: getNumericFieldAsString(rootTaskInventory.inventory, 'weight'),
				className: 'task-inventory-weight',
				icon: faWeight
			},
			{
				content: getNumericFieldAsString(rootTaskInventory.inventory, 'price'),
				className: 'task-inventory-price',
				icon: faCoins
			},
			{
				content: getNumericFieldAsString(rootTaskInventory.inventory, 'scan_string'),
				className: 'task-inventory-scan-string',
				icon: faBarcodeScan
			}
		],
		[rootTaskInventory, translations]
	);

	const getFormItemName = useCallback(
		(propertyName: string): string => {
			return rootTaskInventory.id + TASK_INVENTORY_CONSTANTS.nameDelimiter + propertyName;
		},
		[rootTaskInventory]
	);

	const TASK_INVENTORY_EDITABLE_ITEMS = useMemo(
		() => [
			{
				name: getFormItemName(TASK_INVENTORY_CONSTANTS.width),
				title: translations.width,
				value: getNumericFieldAsString(rootTaskInventory.inventory, 'width')
			},
			{
				name: getFormItemName(TASK_INVENTORY_CONSTANTS.height),
				title: translations.height,
				value: getNumericFieldAsString(rootTaskInventory.inventory, 'height')
			},
			{
				name: getFormItemName(TASK_INVENTORY_CONSTANTS.length),
				title: translations.inventoryLength,
				value: getNumericFieldAsString(rootTaskInventory.inventory, 'length')
			}
		],
		[rootTaskInventory, translations]
	);

	useEffect(() => {
		if (!form) {
			return;
		}

		for (const taskInventory of TASK_INVENTORY_EDITABLE_ITEMS) {
			if (!form.formState.dirtyFields[taskInventory.name]) {
				form.setValue(taskInventory.name, taskInventory.value);
			}
		}
	}, [form, TASK_INVENTORY_EDITABLE_ITEMS]);

	const taskInventoryData = TASK_INVENTORY_ITEMS.map((item, index) => (
		<div className={classNames('task-inventory-data-item', item.className)} key={index}>
			<FontAwesomeIcon icon={item.icon} className="task-inventory-icon" />
			<span title={item.tooltip} className={classNames({ 'task-inventory-tooltip': item.tooltip })}>
				{item.content}
			</span>
		</div>
	));

	const taskInventoryForm = TASK_INVENTORY_EDITABLE_ITEMS.map((item, index) => {
		if (!form) {
			return;
		}

		return (
			<div className="task-inventory-data-item task-inventory-form-item" key={index}>
				<Controller
					as={<Input placeholder={item.title} />}
					control={form.control}
					name={item.name}
					defaultValue={item.value}
				/>
			</div>
		);
	});

	const [showEditForm, setShowEditForm] = useState(false);

	const showForm = useCallback(() => {
		setShowEditForm(true);
	}, [showEditForm]);

	const hideForm = useCallback(() => {
		setShowEditForm(false);
	}, [showEditForm]);

	const onEditClick = useCallback(event => {
		showForm();
		event.preventDefault();
	}, []);

	const onTaskInventoryDelete = useCallback(() => {
		form.setValue(getFormItemName(TASK_INVENTORY_CONSTANTS.originalQuantity), 0, { shouldDirty: true });
	}, [form]);

	const onFormSave = useCallback(() => {
		hideForm();
	}, []);

	return (
		<div className="task-inventory">
			<div className="task-inventory-header">
				#{_get(rootTaskInventory.inventory, 'id')}
				{rootTaskInventory.pending ? (
					<Label text={translations.collectText} size="small" className="orange" />
				) : (
					<Label text={translations.deliverText} size="small" className="green" />
				)}
				{isInEditMode && (
					<FontAwesomeIcon icon={faTrash} className="task-inventory-delete" onClick={onTaskInventoryDelete} />
				)}
			</div>
			<div className="task-inventory-content">
				<div>
					<div className="task-inventory-title">
						{`${rootTaskInventory.original_quantity} x ${_get(rootTaskInventory.inventory, 'name')}`}
					</div>
					<div className="task-inventory-quantity">
						<span>
							{`${rootTaskInventory.quantity || 0} / ${rootTaskInventory.original_quantity} `}
							{rootTaskInventory.pending ? translations.collectedText : translations.deliveredText}
						</span>
						{rootTaskInventory.rejected_quantity ? (
							<span className="task-inventory-rejected-quantity">
								{`${rootTaskInventory.rejected_quantity} ${translations.rejectedText}`}
							</span>
						) : null}
					</div>
				</div>

				{isInEditMode && form && (
					<div className="task-inventory-edit-quantity">
						<Controller
							as={<NumberInput title={translations.quantity} minValue={0} />}
							control={form.control}
							defaultValue={rootTaskInventory.original_quantity}
							name={getFormItemName(TASK_INVENTORY_CONSTANTS.originalQuantity)}
						/>
					</div>
				)}
			</div>

			<div className="task-inventory-data">{isInEditMode ? taskInventoryForm : taskInventoryData}</div>

			{!isInEditMode && (
				<div className="task-inventory-edit-link" onClick={onEditClick}>
					{translations.editItem}
				</div>
			)}

			<Modal
				title={translations.editItem}
				className="task-inventory-edit-modal"
				visible={showEditForm}
				onOk={hideForm}
				onCancel={hideForm}
				footer={null}>
				<TaskInventoryForm
					parentTaskInventory={rootTaskInventory}
					taskInventories={taskInventories}
					onQuantityChange={onQuantityChange}
					onInventoriesChange={onInventoriesChange}
					onSave={onFormSave}
					onCancel={hideForm}
					translations={translations}
				/>
			</Modal>
		</div>
	);
};

export default TaskInventory;
