import * as React from 'react';
import { Menu as AntdMenu } from 'antd';
import { MenuProps as AntdMenuProps } from 'antd/lib/menu';

interface MenuProps extends AntdMenuProps {}

const Menu: React.FC<MenuProps> = (props: MenuProps) => {
	return <AntdMenu {...props} />;
};

export default Menu;
