import * as React from 'react';
import { Layout as AntdLayout } from 'antd';
import { LayoutProps as AntdLayoutProps } from 'antd/lib/layout';

interface LayoutProps extends AntdLayoutProps {}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
	return <AntdLayout {...props} />;
};

export default Layout;
