import React from 'react';
import map from 'lodash/map';
import CopyToClipboard from '../copy-to-clipboard/copy-to-clipboard';

export interface LegendDictionary {
	key: string;
	description: string;
}

interface Props {
	dictionary: LegendDictionary[];
	title: string | React.ReactNode;
	keyValueSeparator?: string | React.ReactNode;
	description?: string | React.ReactNode;
}

const VariableLegend: React.FC<Props> = ({ title, description, dictionary, keyValueSeparator }: Props) => {
	return (
		<div className="legend-container">
			<div className="legend">
				<div className="title">{title}</div>
				{description && <div className="sub-title">{description}</div>}
				{map(dictionary, ({ key, description }) => (
					<div className="entry" key={key}>
						<CopyToClipboard text={key} />
						{keyValueSeparator}
						<span className="description">{description}</span>.
					</div>
				))}
			</div>
		</div>
	);
};
export default VariableLegend;
