import * as React from 'react';
import { Layout as AntdLayout } from 'antd';
import { LayoutProps as AntdLayoutProps } from 'antd/lib/layout';

interface LayoutHeaderProps extends AntdLayoutProps {}

const LayoutHeader: React.FC<LayoutHeaderProps> = (props: LayoutHeaderProps) => {
	return <AntdLayout.Header {...props} />;
};

export default LayoutHeader;
