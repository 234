import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

import classNames from 'classnames';

interface Props extends InputProps {
	containerClassName?: string;
}

const InputWithTitle: React.FC<Props> = ({ containerClassName, placeholder, ...otherProps }) => {
	return (
		<div className={classNames('input-with-title', containerClassName)}>
			<span className="input-with-title-title">{placeholder}</span>
			<Input {...otherProps} />
		</div>
	);
};

export default InputWithTitle;
