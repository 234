import ButtonWrapper from '../../button/button';
import React from 'react';

export interface Translations {
	removeButtonText: string;
	applyButtonText: string;
	cancelButtonText: string;
	editButtonText: string;
}

interface Props {
	editMode: boolean;
	onRemove: () => void;
	onApply: () => void;
	onCancel: () => void;
	onEdit: () => void;
	translations: Translations;
}

const CardFooterWithActionButtons: React.FunctionComponent<Props> = ({
	editMode,
	onRemove,
	onApply,
	onCancel,
	onEdit,
	translations
}: Props) => {
	return (
		<div className="card-footer-with-action-buttons">
			{editMode ? (
				<>
					<ButtonWrapper className="action-button-remove" type={'danger'} onClick={onRemove}>
						{translations.removeButtonText}
					</ButtonWrapper>
					<span className="push-right">
						<ButtonWrapper className="action-button-cancel" onClick={onCancel}>
							{translations.cancelButtonText}
						</ButtonWrapper>
						<ButtonWrapper className="action-button-apply" type={'primary'} onClick={onApply}>
							{translations.applyButtonText}
						</ButtonWrapper>
					</span>
				</>
			) : (
				<ButtonWrapper className="push-right action-button-edit" type={'primary'} onClick={onEdit}>
					{translations.editButtonText}
				</ButtonWrapper>
			)}
		</div>
	);
};

CardFooterWithActionButtons.defaultProps = {
	editMode: false
};

export default CardFooterWithActionButtons;
