import * as React from 'react';
import MetricCounter, { MetricType } from '../metric-counter/metric-counter';
import classNames from 'classnames';

interface Props {
	description: string;
	metricValue: number;
	metricType: MetricType;
	className?: string;
}

const DetailedMetricCounter = ({ description, metricValue, metricType, className }: Props) => {
	const mergedClass = className ? classNames('detailed-metric-counter', className) : 'detailed-metric-counter';
	return (
		<div className={mergedClass}>
			<MetricCounter
				metricValue={metricValue}
				metricType={metricType}
				className="detailed-metric-counter-value"
			/>
			<div className="detailed-metric-counter-description">{description}</div>
		</div>
	);
};

export default DetailedMetricCounter;
