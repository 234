import React from 'react';
import _reduce from 'lodash/reduce';
import _isNil from 'lodash/isNil';
import MatchedNode from './matched-node/matched-node';
import { getAncestors } from '../../../tree-utils';
import CheckboxTree, { Props as CheckboxTreeProps } from '../../checkbox-tree';

export interface Props extends CheckboxTreeProps {
	nodeIdsToHighlight?: string[];
	textToHighlight?: string;
	activeNodeId?: string;
}

export const _getCollapseOverrides = (tree, activeNodeId) => {
	const parents = getAncestors(tree, activeNodeId);
	return _reduce(
		parents,
		(acc, parentId) => {
			acc[parentId] = false;
			return acc;
		},
		{}
	);
};

export const _getMatchedNodes = (tree, nodeIdsToHighlight, text) => {
	return _reduce(
		nodeIdsToHighlight,
		(matchedNodes, nodeId) => {
			const index = tree?.[nodeId]?.option?.indexOf(text);
			if (!_isNil(index) && index > -1) {
				matchedNodes[nodeId] = {
					matchPosition: {
						start: index,
						end: index + text.length
					}
				};
			}
			return matchedNodes;
		},
		{}
	);
};

const TreeWithHighlights: React.FC<Props> = ({
	tree,
	nodeIdsToHighlight,
	textToHighlight,
	activeNodeId,
	...otherProps
}) => {
	const collapseOverrides = _getCollapseOverrides(tree, activeNodeId);
	const matchedNodes = _getMatchedNodes(tree, nodeIdsToHighlight, textToHighlight);
	return (
		<CheckboxTree
			tree={tree}
			{...otherProps}
			overrideCollapse={collapseOverrides}
			renderNode={(node, nodeId) => {
				return nodeId in matchedNodes ? (
					<MatchedNode
						start={matchedNodes[nodeId].matchPosition.start}
						end={matchedNodes[nodeId].matchPosition.end}
						text={node.option}
						id={nodeId}
						isActive={activeNodeId === nodeId}
					/>
				) : (
					<span>{node.option}</span>
				);
			}}
		/>
	);
};

export default TreeWithHighlights;
