import React from 'react';
import { NoteType, TaskNote } from '@bringg/types/types/task';
import DateDisplay from '../date-display/date-display';

export interface Translations {
	signature: string;
	photo: string;
	additionalServices: string;
}

interface TaskNotesAttachmentsProps {
	taskNotesAttachments: TaskNote[];
	dateFormat: string;
	translations: Translations;
}

const TaskNotesAttachments: React.FC<TaskNotesAttachmentsProps> = ({
	taskNotesAttachments,
	dateFormat,
	translations
}) => {
	const getAttachmentName = (noteType: NoteType): string => {
		switch (noteType) {
			case NoteType.TaskPhoto: {
				return translations.photo;
			}

			case NoteType.Signature: {
				return translations.signature;
			}

			case NoteType.Form: {
				return translations.additionalServices;
			}
		}
	};

	const getAttachmentImage = (taskNote: TaskNote) => {
		if (!taskNote.url) {
			return;
		}

		return (
			<a href={taskNote.url.toString()} target="_blank">
				<img className="task-note-attachment-note-image" src={taskNote.url.toString()} />
			</a>
		);
	};

	const getAttachmentNote = (taskNote: TaskNote) => {
		if (!taskNote.note || taskNote.type !== NoteType.Form) {
			return;
		}

		const notes = Object.keys(taskNote.note).map((key, index) => {
			const noteContent = taskNote.note[key];

			return (
				<div className="task-note-attachment-form" key={index}>
					<div className="task-note-attachment-form-title">{key}</div>
					<div>{noteContent}</div>
				</div>
			);
		});

		return <div className="task-note-attachment-note task-note-attachment-notes">{notes}</div>;
	};

	const attachments = taskNotesAttachments.map((taskNote: TaskNote) => (
		<div className="task-note-attachment" key={taskNote.id}>
			<div>
				<div className="task-note-attachment-title">{getAttachmentName(taskNote.type)}</div>

				<div className="task-note-attachment-date">
					<DateDisplay date={taskNote.created_at} format={dateFormat} />
				</div>

				{getAttachmentImage(taskNote)}
				{getAttachmentNote(taskNote)}
			</div>
		</div>
	));

	return <div className="task-notes-attachments">{attachments}</div>;
};

export default TaskNotesAttachments;
