import * as React from 'react';
import MetricCounter, { MetricType } from '../metric-counter/metric-counter';
import { CSSProperties } from 'react';

interface Props {
	count: number;
	title?: string;
	percentage?: number;
	className?: string;
	style?: CSSProperties;
}

const CombinedMetricCounter = ({ title, count, percentage, className, style }: Props) => {
	const mergedClass = className ? `combined-metric ${className}` : 'combined-metric';
	return (
		<div className={mergedClass} style={style}>
			{title ? <h3>{title}</h3> : null}
			<div className="combined-metric-counters">
				<MetricCounter
					metricValue={count}
					metricType={MetricType.NUMBER}
					className="combined-metric-main-counter"
				/>
				{percentage ? (
					<MetricCounter
						metricValue={percentage}
						metricType={MetricType.PERCENTAGE}
						className="combined-metric-main-percentage"
					/>
				) : null}
			</div>
		</div>
	);
};

export default CombinedMetricCounter;
