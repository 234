import React, { PropsWithChildren, useCallback, useState } from 'react';
import TooltipWrapper from '../tooltip/tooltip';
import _noop from 'lodash/noop';

interface Props {
	text: string;
	copySuccessTooltipContent?: string | React.ReactNode;
	onCopyClicked?: () => void;
	showText?: boolean;
}

const DEFAULT_COPY_TEXT = 'Copied!';

const CopyToClipboard: React.FC<Props> = ({
	showText = true,
	children,
	text,
	copySuccessTooltipContent,
	onCopyClicked = _noop
}: PropsWithChildren<Props>) => {
	const [isCopied, setIsCopied] = useState(false);

	const copyToClipboard = useCallback(() => {
		const dummyElement = document.createElement('input');
		document.body.appendChild(dummyElement);
		dummyElement.setAttribute('value', text);
		dummyElement.select();
		document.execCommand('copy');
		document.body.removeChild(dummyElement);

		setIsCopied(true);
		setTimeout(() => setIsCopied(false), 1000);

		onCopyClicked();
	}, []);

	return (
		<span className="copy-to-clipboard">
			{isCopied ? (
				<TooltipWrapper title={copySuccessTooltipContent || DEFAULT_COPY_TEXT} visible={true}>
					<span className="copy-button">{children ? children : <i className="fas fa-check"></i>}</span>
				</TooltipWrapper>
			) : (
				<span className="copy-button" onClick={copyToClipboard}>
					{children ? children : <i className="fal fa-copy"></i>}
				</span>
			)}
			{showText && <span className="text-span">{text}</span>}
		</span>
	);
};
export default CopyToClipboard;
