import React, { FunctionComponent, useCallback } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { RouteInfo } from '../pages';

interface Props {
	routes: RouteInfo[];
}

const PagesHeader: FunctionComponent<Props> = ({ routes }) => {
	let { url: path } = useRouteMatch();
	const { pathname } = useLocation();

	const isCurrentSelected = useCallback(
		(selectedPath: string) => {
			return pathname === selectedPath ? 'active' : '';
		},
		[pathname]
	);

	const PageHeaderItem = (route: RouteInfo) => (
		<span className={classNames('page-header-item', isCurrentSelected(`${path}/${route.path}`))}>
			<Link className="page-header-item-link" to={`${path}/${route.path}`}>
				{route.title}
			</Link>
		</span>
	);

	return (
		<div className="page-header">
			{routes.map(route => (
				<PageHeaderItem key={route.path} path={route.path} title={route.title} />
			))}
		</div>
	);
};

export default PagesHeader;
